import { arbitrum, polygonMumbai, avalanche, polygon, mainnet, optimism, bsc, bscTestnet } from 'wagmi/chains';
import USDTLogo from 'assets/images/logos/usdt.png';
import DAILogo from 'assets/images/logos/dai.png';
import USDCLogo from 'assets/images/logos/usdc.png';
import NXNLogo from 'assets/images/logos/logo-outline-purple.png';
import EnergyLogo2 from 'assets/images/icons/Energy_Symbol.png';
import TextureUSDC from 'assets/images/backgrounds/mountainTextureBlue.png';
import TextureYellow from 'assets/images/backgrounds/mountainTextureYellow.png';
import TextureEnergy from 'assets/images/backgrounds/mountainTexturePurple.png';
import TextureUSDT from 'assets/images/backgrounds/mountainTextureGreen.png';
import isTestnet from './is-testnet';
import AvaLogo from 'assets/images/logos/ava-logo.png';
import EthLogo from 'assets/images/logos/eth-logo.png';
import PolyLogo from 'assets/images/logos/poly-logo.png';
import ArbLogo from 'assets/images/logos/arb-logo.png';
import OpLogo from 'assets/images/logos/op-logo.png';
import BnbLogo from 'assets/images/logos/bnb-logo.png';
import PulseLogo from 'assets/images/logos/pulse-logo.png';

export const TOKEN_BACKGROUNDS = {
    usdc: TextureUSDC,
    nxn: TextureEnergy,
    dai: TextureYellow,
    usdt: TextureUSDT,
    energy: TextureYellow,
};

export const TOKEN_LOGOS = {
    usdc: USDCLogo,
    nxn: NXNLogo,
    dai: DAILogo,
    usdt: USDTLogo,
    energy: EnergyLogo2,
};

export const TOKEN_NAME_TO_LOGOS = {
    'NXN Token': NXNLogo,
};

export const MAIN_TOKEN_DECIMALS = 9;

export const SECONDS_IN_DAY = 14400;
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const DEAD_ADDRESS = '0x000000000000000000000000000000000000dEaD';
export const CHAIN = !isTestnet() ? 43114 : 97;
export const CHAIN_NAME = !isTestnet() ? 'Avax' : 'BSC Testnet';

export const DECIMALS = {
    nxn: 9,
    usdt: 6,
    dai: 18,
    usdc: 6,
};

export const DECIMALS_BSC = {
    usdt: 18,
    dai: 18,
    usdc: 18,
};

export function getDecimals(chain, asset) {
    if (chain === 56) return DECIMALS_BSC[asset];
    DECIMALS[asset];
}

export const RPCS = {
    80001: 'https://rpc-mumbai.maticvigil.com/',
    97: 'https://data-seed-prebsc-1-s1.bnbchain.org:8545',
    137: 'https://polygon-rpc.com/',
    42161: 'https://arb1.arbitrum.io/rpc',
    421614: 'https://sepolia-rollup.arbitrum.io/rpc',
    43114: 'https://api.avax.network/ext/bc/C/rpc',
};

export const BlockExplorer = {
    mumbai: 'https://mumbai.polygonscan.com/',
    polygon: 'https://polygonscan.com/',
    arbitrum: 'https://arbiscan.io',
    arbtest: 'https://testnet.arbiscan.io',
    bsctest: 'https://testnet.bscscan.com',
    avalanche: 'https://snowtrace.io',
};

export const BlogLink = 'https://google.com';
export const SupportEmail = 'support@nxn.com';
export const SAFT_LINK = 'https://docs.nxn.network/legal/saft';
export const PRIVACY_LINK = 'https://docs.nxn.network/legal/privacy-policy';
export const TERMS_LINK = 'https://docs.nxn.network/legal/terms-of-use';
export const LITE_LINK = 'https://docs.nxn.network/documentation/litepaper';
export const VS_LINK = 'https://docs.nxn.network/documentation/nxn-vs-bitcoin';
export const HOW_BUY_LINK = 'https://docs.nxn.network/documentation/how-to-buy';
export const BUY_GAS = 'https://app.1inch.io/#/137/simple/swap/USDC/MATIC';
export const HOW_STAKE_LINK = 'https://docs.nxn.network/documentation/how-to-stake';
export const TWITTER_LINK = 'https://twitter.com/nxn_network';
export const DISCORD_LINK = 'https://discord.gg/U2BM6B3E3B';
export const MEDIUM_LINK = 'https://medium.com/@nxn-network';
export const GITHUB_LINK = 'https://github.com/NXN-Network';
export const GITBOOK_LINK = 'https://docs.nxn.network';
export const TEST_NET_LINK = 'https://mine-dev.nxn.network';
export const SAC_ADDRESS = '0x59dd53dE47F62b220Fa3065A97b960faB095dcF3';

const pulseChain = {
    id: 369,
    network: 'pulsechain',
    name: 'Pulsechain',
    nativeCurrency: { name: 'Pulse', symbol: 'PLS', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://rpc.pulsechain.com'],
            webSocket: ['wss://ws.pulsechain.com'],
        },
        public: {
            http: ['https://rpc.pulsechain.com'],
            webSocket: ['wss://ws.pulsechain.com'],
        },
    },
    blockExplorers: {
        default: {
            name: 'Etherscan',
            url: 'https://scan.pulsechain.com',
        },
    },
    contracts: {
        ensRegistry: {
            address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
        },
        multicall3: {
            address: '0xca11bde05977b3631167028862be2a173976ca11',
            blockCreated: 14353601,
        },
    },
};

export const arbitrumSepolia = {
    id: 421_614,
    name: 'Arbitrum Sepolia',
    nativeCurrency: {
        name: 'Arbitrum Sepolia Ether',
        symbol: 'ETH',
        decimals: 18,
    },
    rpcUrls: {
        alchemy: {
            http: ['https://arb-sepolia.g.alchemy.com/v2'],
            webSocket: ['wss://arb-sepolia.g.alchemy.com/v2'],
        },
        default: {
            http: ['https://sepolia-rollup.arbitrum.io/rpc'],
        },
    },
    blockExplorers: {
        etherscan: { name: 'Arbiscan', url: 'https://sepolia.arbiscan.io' },
        default: { name: 'Arbiscan', url: 'https://sepolia.arbiscan.io' },
    },
    contracts: {
        multicall3: {
            address: '0xca11bde05977b3631167028862be2a173976ca11',
            blockCreated: 81930,
        },
    },
    testnet: true,
};

export const allowedChains = !isTestnet() ? [avalanche] : [bscTestnet];
export const chainLogos = {
    [polygonMumbai.id]: PolyLogo,
    [arbitrumSepolia.id]: ArbLogo,
    [arbitrum.id]: ArbLogo,
    [avalanche.id]: AvaLogo,
    [polygon.id]: PolyLogo,
    [mainnet.id]: EthLogo,
    [bsc.id]: BnbLogo,
    [bscTestnet.id]: BnbLogo,
    [optimism.id]: OpLogo,
    [pulseChain.id]: PulseLogo,
};
