import React, { useEffect } from 'react';
// Modules
import { get } from 'redux/actions/dashboard.js';
import { ContractContext } from 'web3/WagmiListener.js';
import { useDispatch, useSelector } from 'react-redux';
// UI
import { Box, Container, Grid } from '@mui/material';
import WalletCard from 'components/Cards/WalletCard/WalletCard.js';
// Styles
import EnergyCard from 'components/Cards/EnergyCard/EnergyCard.js';
import MiningCard from './Cards/MiningCard.js';
import BondingCard from './Cards/BondingCard.js';
import useStyles from './styles';
import isTestnet from 'utils/is-testnet.js';
import Overview from './Overview/Overview.js';

export default function Home() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { balances } = useSelector(({ balances }) => ({ balances }));
    const { initialized } = React.useContext(ContractContext);

    useEffect(() => {
        if (initialized) dispatch(get());
    }, [initialized]);

    return (
        <Box className={classes.root}>
            {/* <img src={backgroundImg} className={classes.background} /> */}
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} className={classes.gridItem}>
                        <WalletCard balance="nxn" color="primary" usdPer={balances.nxnPrice} />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.gridItem}>
                        <EnergyCard />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {isTestnet() && <BondingCard />}
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.gridItem}>
                        {isTestnet() && <MiningCard />}
                        {/* {isTestnet() && <TestnetCard />} */}
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.gridItem}>
                        <Overview />
                        {/* {isTestnet() && <TestnetCard />} */}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
