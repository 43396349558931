import React, { useState } from 'react';
import { useWeb3Modal } from '@web3modal/react';
import { useAccount } from 'wagmi';
import { useDispatch, useSelector } from 'react-redux';

import { setSnackbar } from 'redux/actions/snackbar';
import { metamaskErrorWrap } from 'utils/metamask-error';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography, useTheme } from '@mui/material';

import useStyles from './styles';
import { TOKEN_BACKGROUNDS, TOKEN_LOGOS } from 'utils/constants';
import { buy } from 'redux/actions/energy';
import { Link } from 'react-router-dom';
import isTestnet from 'utils/is-testnet';

export default function EnergyCard({ color = 'energy', balance = 'energy', sx = {}, actions = true }) {
    const classes = useStyles();
    const theme = useTheme();
    const { balances } = useSelector(({ balances }) => ({ balances }));
    const { open } = useWeb3Modal();
    const { isConnected } = useAccount();
    const [refilling, setRefilling] = useState(false);
    const dispatch = useDispatch();

    async function handleRefill() {
        setRefilling(true);
        try {
            await dispatch(buy(50));
            dispatch(setSnackbar({ severity: 'success', message: 'Energy Refilled' }));
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: metamaskErrorWrap(error) }));
        }
        setRefilling(false);
    }

    return (
        <Box
            className={classes.root}
            sx={{ ...sx, borderColor: theme.palette[color].main, background: `url(${TOKEN_BACKGROUNDS[balance]})` }}
        >
            <Box className={classes.row}>
                <img src={TOKEN_LOGOS[balance]} className={classes.walletImage} />
                <Typography variant="h6" fontWeight="600" sx={{ flex: 1, color: theme.palette[color].main }}>
                    Energy Wallet
                </Typography>
                {!isConnected && (
                    <Button color={color} onClick={open}>
                        Connect Wallet
                    </Button>
                )}
                {/* {isConnected && isTestnet() && (
                    <LoadingButton loading={refilling} color={'white'} onClick={handleRefill}>
                        Refill
                    </LoadingButton>
                )} */}
            </Box>
            <Typography variant="font4" marginY={1}>
                Energy Token
            </Typography>
            <Box className={classes.row}>
                <Typography variant="h6" fontWeight={'600'} lineHeight={1}>
                    {balances?.energy?.numberWithCommas(0)}
                </Typography>
                <Typography variant="font4">Energy</Typography>
            </Box>
            {actions && (
                <Box className={classes.actions}>
                    <Button color={color} disabled={!isTestnet()} component={Link} to="/energy-token">
                        Buy or Transfer Energy
                    </Button>
                </Box>
            )}
        </Box>
    );
}
