import { ethers } from 'ethers';
import { TOKEN_NAME_TO_LOGOS } from './constants';

export function formatUserShareDivs(divs) {
    return divs.map((div) => {
        return {
            name: div.name,
            token: div.token,
            amount: div.amount.formatUnits(div.decimals),
            decimals: div.decimals,
            logo: TOKEN_NAME_TO_LOGOS[div.name],
        };
    });
}

export function formatPoolInfo(poolInfo) {
    return {
        pooledTokens: poolInfo?.pooledTokens?.formatUnits?.() ?? 0,
        totalShares: poolInfo?.totalShares?.formatUnits?.() ?? 0,
        totalInterest: poolInfo?.totalInterest?.formatUnits?.() ?? 0,
        currentApr: poolInfo?.currentApr?.formatUnits?.() ?? 0,
        aprSlowdown: poolInfo?.aprSlowdown?.formatUnits?.() ?? 0,
        halving: poolInfo?.halving?.formatUnits?.() ?? 0,
        bonusSharesPerPeriod: poolInfo?.bonusSharesPerPeriod?.formatUnits?.() ?? 0,
        bonusSharesPeriod: poolInfo?.bonusSharesPeriod?.formatUnits?.(0) ?? 0,
        earlyWithdrawPenalty: poolInfo?.earlyWithdrawPenalty?.formatUnits?.() ?? 0,
        decimals: poolInfo?.decimals?.toNumber?.() ?? 0,
        earlyRewardPenalty: poolInfo?.earlyRewardPenalty?.toNumber?.() ?? 0,
        nextInterestTime: poolInfo?.nextInterestTime?.toNumber?.() ?? 0,
        nextYear: poolInfo?.nextYear?.toNumber?.() ?? 0,
        nextHalving: poolInfo?.nextHalving?.toNumber?.() ?? 0,
        yearsBetweenHalving: poolInfo?.yearsBetweenHalving?.toNumber?.() ?? 0,
        secondsBetweenRewards: poolInfo?.secondsBetweenRewards?.toNumber?.() ?? 0,
        secondsInDay: poolInfo?.secondsInDay?.toNumber?.() ?? 0,
        daysInYear: poolInfo?.daysInYear?.toNumber?.() ?? 0,
        startTime: poolInfo?.startTime?.toNumber?.() ?? 0,
        maxStakeLength: poolInfo?.maxStakeLength?.toNumber?.() ?? 0,
        minStakeLength: poolInfo?.minStakeLength?.toNumber?.() ?? 0,
        minStakeAmount: poolInfo?.minStakeAmount?.toNumber?.() ?? 0,
        energyPerTransfer: poolInfo?.energyPerTransfer?.toNumber?.() ?? 0,
        energyPerSplit: poolInfo?.energyPerSplit?.toNumber?.() ?? 0,
        energyPerRename: poolInfo?.energyPerRename?.toNumber?.() ?? 0,
        energyPerStake: poolInfo?.energyPerStake?.toNumber?.() ?? 0,
    };
}

export function stakeFormatter(stakeData) {
    const stake = {
        data: {
            amount: stakeData.stake.amount.formatUnits(),
            shares: stakeData.stake.shares.formatUnits(),
            startDay: stakeData.stake.startDay.toNumber(),
            sLength: stakeData.stake.sLength.toNumber(),
            start: stakeData.stake.start.toNumber(),
            name: stakeData.stake.name,
            withdrawn: stakeData.stake.withdrawn.toNumber(),
        },
        rewards: stakeData.rewards.formatUnits(),
        penalty: stakeData.penalty.formatUnits(),
        id: stakeData.id.toNumber(),
        uri: stakeData.uri,
        uriData: decodeURI(stakeData.uri.replace('data:application/json;base64,', '')),
    };
    return stake;
}

export function offeringFormatter(offering) {
    return {
        name: offering.name,
        endTime: offering.endTime.toNumber(),
        limit: offering.limit.formatUnits(),
        price: offering.price.formatUnits(18),
        vestingDays: offering.vestingDays.toNumber(),
        available: offering.available,
    };
}

export function adoptionDataFormatter(data) {
    return {
        currentSold: data?.currentSold?.formatUnits?.() ?? 0,
        maxSold: data?.maxSold?.formatUnits?.() ?? 0,
        daysUntilVestedPortion: data?.daysUntilVestedPortion ?? 99,
        decimals: data?.decimals ?? 9,
        startTime: data?.startTime?.toNumber?.() ?? 0,
        secondsBetweenDays: data?.secondsBetweenDays ?? 86400,
        minimumTokenPurchase: data?.minimumTokenPurchase?.formatUnits?.(9) ?? 0,
    };
}

export function adoptionUserItemFormatter(offer) {
    return {
        item: {
            offeringId: offer.item.offeringId,
            principle: offer.item.principle,
            referrer: offer.item.referrer,
            startTime: offer.item.startTime.toNumber(),
            vestingDays: offer.item.vestingDays,
            vestedAmount: offer.item.vestedAmount.formatUnits(),
            redeemed: offer.item.redeemed,
        },
        id: offer.id.toNumber(),
        lengthVested: offer.lengthVested.toNumber(),
        penalty: offer.penalty.formatUnits(),
        percentVested: offer.percentVested.formatUnits(2),
        redeemAmount: offer.redeemAmount.formatUnits(),
    };
}

export function adoptionOverallFormatter(overall) {
    return {
        ...overall,
        spent: overall?.spent?.formatUnits?.(18) ?? 0,
        vested: overall?.vested?.formatUnits?.(9) ?? 0,
        penalties: overall?.penalties?.formatUnits?.(9) ?? 0,
        redeemed: overall?.redeemed?.formatUnits?.(9) ?? 0,
    };
}

export function stakingOverallFormatter(overall) {
    return {
        ...overall,
        deposited: overall.deposited.formatUnits(6),
        withdrawn: overall.withdrawn.formatUnits(9),
        penalty: overall.penalty.formatUnits(9),
        rewards: overall.rewards.formatUnits(9),
    };
}
export function bondingOverallFormatter(overall) {
    return {
        ...overall,
        daiBondDeposits: overall.daiBondDeposits.formatUnits(18),
        daiBondPayouts: overall.daiBondPayouts.formatUnits(9),
        usdcBondDeposits: overall.usdcBondDeposits.formatUnits(18),
        usdcBondPayouts: overall.usdcBondPayouts.formatUnits(9),
        totalDeposits: overall.totalDeposits.formatUnits(18),
        totalPayouts: overall.totalPayouts.formatUnits(9),
        totalRedeemed: overall.totalRedeemed.formatUnits(9),
    };
}

export function treasuryV2VariablesFormatter(variables) {
    console.log(variables);
    return {
        initialTokens: variables?.[0]?.formatUnits?.(9) || 0,
        currentBurnIdx: variables?.[1]?.formatUnits(0),
        nextBurn: variables?.[2]?.formatUnits?.(0),
        secondsInDay: variables?.[3]?.formatUnits?.(0),
        burns: [10, 20, 30, 40],
    };
}

export function bondInfoFormatter(info) {
    return {
        token: info?.[0] ?? ethers.constants.AddressZero,
        principle: info?.[1] ?? ethers.constants.AddressZero,
        premium: info?.[2]?.formatUnits?.(2) ?? 0,
        discount: info?.[3]?.formatUnits?.(2) ?? 0,
        minStakingDays: info?.[4]?.toNumber?.() ?? 0,
        vestingLength: info?.[5]?.toNumber?.() ?? 0,
    };
}

export function userBondFormatter(bond) {
    return {
        id: bond.bondId.toNumber(),
        penalties: bond.penalties.formatUnits(9),
        payout: bond.payout.formatUnits(9),
        info: {
            startTime: bond.bond.startTime.toNumber(),
            payout: bond.bond.payout.formatUnits(9),
            pricePaid: bond.bond.pricePaid.formatUnits(9),
            vesting: bond.bond.vesting.toNumber(),
        },
        uri: decodeURI(bond.uri.replace('data:application/json;base64,', '')),
        token: {
            repaid: bond.bondToken.repaid,
            bond: bond.bondToken.bond,
        },
    };
}

export function formatFreeClaimValues(values) {
    return {
        claimAmount: values?.[0]?.formatUnits?.() ?? 0,
        totalClaimed: values?.[1]?.formatUnits?.() ?? 0,
        maxClaimAmount: values?.[2]?.formatUnits?.() ?? 0,
        claimOver: values?.[3]?.toNumber?.() ?? 0,
        referralBonus: values?.[4]?.toNumber?.() ?? 0,
    };
}

export function sacrificeStatFormatter(values) {
    return {
        totalSacrificed: values?.[0]?.formatUnits?.(18) ?? 0,
        topSacrifice: values?.[1]?.formatUnits?.(18) ?? 0,
        totalSacrifices: values?.[2]?.formatUnits?.(0) ?? 0,
    };
}

export function sacrificeFormatter(sacrifices) {
    const formatted = [];
    sacrifices?.forEach?.((point) => {
        formatted.push({
            paidAmount: point.paidAmount?.formatUnits?.(18) ?? 0,
            vestedAmount: point.vestedAmount?.formatUnits?.(18) ?? 0,
            referrer: point.referrer,
            account: point.account,
        });
    });
    return formatted;
}

/** Utils */
function decodeURI(uri) {
    return JSON.parse(Buffer.from(uri, 'base64').toString());
}
