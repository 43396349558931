export const SET_ENERGY = 'SET_ENERGY';
export const ENERGY_ERROR = 'ENERGY_ERROR';
export const INIT = {};

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_ENERGY:
            return payload;
        case ENERGY_ERROR:
        default:
            return state;
    }
}

export default reducer;
