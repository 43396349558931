export const SET_DASHBOARD = 'SET_DASHBOARD';
export const DASHBOARD_ERROR = 'DASHBOARD_ERROR';
export const INIT = {};

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_DASHBOARD:
            return payload;
        case DASHBOARD_ERROR:
        default:
            return state;
    }
}

export default reducer;
