import React, { useEffect, useState } from 'react';
// Modules
import { useDispatch, useSelector } from 'react-redux';
import { get as getStaking, updatePool } from 'redux/actions/staking';
import { ContractContext } from 'web3/WagmiListener';
import { get as getEnergy } from 'redux/actions/energy';
// UI
import { Alert, Box, Button, Container, Grid, Typography } from '@mui/material';
import WalletCard from 'components/Cards/WalletCard/WalletCard';
import EnergyCard from 'components/Cards/EnergyCard/EnergyCard';
import MineInput from './MineInput/MineInput';
import MyDeposits from './MyDeposits/MyDeposits';
import TimingCard from 'components/Cards/TimingCard/TimingCard';
import InfoDialog from 'components/Dialogs/InfoDialog/InfoDialog';
// Styles
import useStyles from './styles';
import { setSnackbar } from 'redux/actions/snackbar';
import { metamaskErrorWrap } from 'utils/metamask-error';
import { LoadingButton } from '@mui/lab';
import DivTokens from './DivTokens/DivTokens';

export default function Mining() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [infoOpen, setInfoOpen] = useState(false);
    const [updatingPool, setUpdatingPool] = useState(false);
    const { initialized } = React.useContext(ContractContext);
    const { staking } = useSelector(({ staking }) => ({ staking }));

    useEffect(() => {
        if (initialized) dispatch(getStaking());
    }, [initialized]);

    async function handleUpdatePool() {
        setUpdatingPool(true);
        try {
            await dispatch(updatePool());
            dispatch(setSnackbar({ message: 'Pool updated!', severity: 'success' }));
        } catch (error) {
            dispatch(setSnackbar({ message: metamaskErrorWrap(error), severity: 'error' }));
        }
        setUpdatingPool(false);
    }

    const totalSupply = (staking?.poolInfo?.pooledTokens ?? 0) + (staking?.totalSupply ?? 0) - (staking?.treasurySupply ?? 0);
    const minMintedYearly = (totalSupply * (staking?.poolInfo?.currentApr ?? 0)) / 100;

    if (!staking.live) {
        return (
            <Alert severity="error" icon={false}>
                <Typography variant="h6">Mining is not currently live, please check back later.</Typography>
            </Alert>
        );
    }

    return (
        <Box className={classes.root}>
            <InfoDialog open={Boolean(infoOpen)} property={infoOpen} onClose={() => setInfoOpen(false)} />

            <Container maxWidth="lg">
                <Typography variant="h5">eMining</Typography>
                <Typography variant="font2" maxWidth={'850px'}>
                    The total amount of <span className="bold">circulating supply is {totalSupply.shorten(2)} NXN tokens</span>, so{' '}
                    <span className="bold">{minMintedYearly.numberWithCommas(2)} NXN tokens</span> are paid out per year, depending on your{' '}
                    <span className="bold">% of shares for your mining deposit</span> you will{' '}
                    <span className="bold">earn a % of the tokens mined per year</span>. You can determine your potential current and future
                    earnings with our{' '}
                    <a className="links underline" href={'https://docs.nxn.network/documentation/mining-calculator'}>
                        Mining Calculator.
                    </a>{' '}
                    <br />
                    The current amount committed to mining is{' '}
                    <span className="bold">{staking?.poolInfo?.pooledTokens?.numberWithCommas?.(0)} NXN</span>. The total number of shares
                    is <span className="bold">{staking?.poolInfo?.totalShares?.numberWithCommas?.(0)}</span>
                </Typography>
                <Typography maxWidth={'650px'} variant="font2" mt={2}>
                    <span className="bold">Testnet Note:</span> Please start miner before the timer ends if looking to earn NXN on the
                    testnet. We encourage users to start additional miners with longer commitments to help test the network. Users can also
                    alter their miners using the energy NFT. Enjoy.
                </Typography>
                <Grid container spacing={3} sx={{ marginTop: 3 }}>
                    <Grid item xs={12} md={8}>
                        <Box className={classes.cards}>
                            <EnergyCard />
                            <WalletCard color="nxn" balance="nxn" />
                        </Box>

                        <MineInput sx={{ marginTop: 2 }} />
                        <DivTokens sx={{ marginTop: 2 }} />
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.rightColumn}>
                        <TimingCard
                            actionButton={
                                <LoadingButton color="secondary" loading={updatingPool} onClick={handleUpdatePool}>
                                    Update Pool
                                </LoadingButton>
                            }
                            title="Next Payout"
                            endTime={staking?.poolInfo?.nextInterestTime}
                        />
                        <TimingCard onAction={() => setInfoOpen('apr')} title="Next APR Slowdown" endTime={staking?.poolInfo?.nextYear} />
                        <TimingCard onAction={() => setInfoOpen('halving')} title="Next Halving" endTime={staking?.poolInfo?.nextHalving} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <MyDeposits />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
