import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        padding: '24px 0px',
    },
    background: {
        top: 0,
        position: 'fixed',
        height: '100vh',
        width: '100%',
        zIndex: 0,
        objectFit: 'cover',
    },
    container: {
        position: 'relative',
    },

    content: {},
    contentHeader: {
        padding: '32px 24px',

        [theme.breakpoints.down('sm')]: {
            padding: '24px 16px',
        },
    },
    contentDivider: {
        backgroundColor: theme.palette.secondary.main,
        width: '100%',
        height: 1,
    },
    contentBody: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 12,
        alignItems: 'center',
        padding: 24,
        '& > div': {
            flex: 1,
        },

        [theme.breakpoints.down('sm')]: {
            padding: 16,
        },
    },
    inputHeader: {
        padding: '32px 24px',
        backgroundColor: theme.palette.secondary.main,
        textAlign: 'center',
    },

    inputBody: {
        padding: '16px 16px',
    },
    inputActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 12,
    },
    inputFields: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },

    infoItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        padding: '13px 0px',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,

        '& > *:first-child': {
            flexBasis: '50%',
        },
        '& > *:second-child': {
            flexBasis: '30%',
            height: 30,
            objectFit: 'cover',
        },
        '& > *:nth-child(2)': {
            flexBasis: '30%',
        },
    },
    infoImageContainer: {
        display: 'flex',
        justifyContent: 'center',
    },

    tokens: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
    },
    activeToken: {
        backgroundColor: 'rgba(255,255,255,0.2)',
    },
    token: {
        height: 60,
        width: 60,
        '& > img': {
            height: '100%',
            width: '100%',
        },
    },
}));

export default useStyles;
