export const SET_BONDS = 'SET_BONDS';
export const BONDS_ERROR = 'BONDS_ERROR';
export const INIT = {};

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_BONDS:
            return payload;
        case BONDS_ERROR:
        default:
            return state;
    }
}

export default reducer;
