import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {},
    background: {
        top: 0,
        position: 'fixed',
        height: '100vh',
        width: '100%',
        zIndex: 0,
    },
    container: {
        paddingTop: 32,
        paddingBottom: 56,
        position: 'relative',
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
    },
}));

export default useStyles;
