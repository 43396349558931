import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useStyles from './styles';
import { DialogInformation } from './information';

export default function InfoDialog({ open, property, onClose }) {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{DialogInformation[property]?.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{DialogInformation[property]?.body}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus>
                    Understood
                </Button>
            </DialogActions>
        </Dialog>
    );
}
