import BlockChain from 'web3/Blockchain';
import cloneDeep from 'lodash/cloneDeep';
import { ENERGY_ERROR, SET_ENERGY } from '../reducers/energy';
import { CHAIN_NAME, CHAIN, DECIMALS } from 'utils/constants';
import { addBalance, getEnergy } from './balances';
import { ethers } from 'ethers';

export const get = () => async (dispatch, getState) => {
    const { address, contracts } = BlockChain.getInfo();

    const pricePerEnergy = (await contracts?.energyToken?.getPricePerEnergy?.()) ?? 0;
    const poolInfo = (await contracts.staking?.getPoolInfo?.()) ?? {};

    const [approvalUSDC, approvalUSDT, approvalDAI] = await Promise.all([
        contracts?.usdc?.allowance?.(address, contracts?.energyToken?.address ?? ethers.constants.AddressZero) ?? 0,
        contracts?.usdt?.allowance?.(address, contracts?.energyToken?.address ?? ethers.constants.AddressZero) ?? 0,
        contracts?.dai?.allowance?.(address, contracts?.energyToken?.address ?? ethers.constants.AddressZero) ?? 0,
    ]);

    return dispatch({
        payload: {
            live: Boolean(contracts.energyToken),
            pricePerEnergy: pricePerEnergy?.toNumber?.(),
            energyPerTransfer: poolInfo?.energyPerTransfer?.toNumber?.() ?? 0,
            energyPerSplit: poolInfo?.energyPerSplit?.toNumber?.() ?? 0,
            energyPerRename: poolInfo?.energyPerRename?.toNumber?.() ?? 0,
            approvals: {
                usdc: approvalUSDC?.formatUnits?.(DECIMALS.usdc) ?? 0,
                dai: approvalDAI?.formatUnits?.(DECIMALS.dai) ?? 0,
                usdt: approvalUSDT?.formatUnits?.(DECIMALS.usdt) ?? 0,
            },
        },
        type: SET_ENERGY,
    });
};

export const approve = (token) => async (dispatch, getState) => {
    const { signer, contracts, chain } = BlockChain.getInfo();

    const energy = cloneDeep(getState().energy);
    const units = DECIMALS[token];

    try {
        if (chain !== CHAIN) throw new Error(`Connected to an unsupported chain, please connect to ${CHAIN_NAME}`);
        if (!signer) throw new Error('Please connect your wallet');

        const tx = await contracts[token].connect(signer).approve(contracts.energyToken.address, ethers.constants.MaxUint256);
        const finished = await tx.wait(); // tx.hash
        const event = finished.events.find((e) => e.event === 'Approval');

        if (event) {
            energy.approvals[token] = event.args.value.formatUnits(units);
        }

        return dispatch({
            payload: energy,
            type: SET_ENERGY,
        });
    } catch (error) {
        dispatch({
            type: ENERGY_ERROR,
        });

        throw error;
    }
};

export const buy = (amount, asset) => async (dispatch, getState) => {
    if (!asset) asset = 'usdc'; // literally only because of eslint

    const { signer, contracts, chain } = BlockChain.getInfo();

    const energy = cloneDeep(getState().energy);

    try {
        if (!signer) throw new Error('Please connect your wallet');
        if (chain !== CHAIN) throw new Error(`Connected to an unsupported chain, please connect to ${CHAIN_NAME}`);

        const tx = await contracts.energyToken.connect(signer).buyEnergy(contracts[asset].address, amount);
        await tx.wait();

        dispatch(addBalance('energy', parseInt(amount)));

        return dispatch({
            payload: energy,
            type: SET_ENERGY,
        });
    } catch (error) {
        dispatch({
            type: ENERGY_ERROR,
        });
        throw error;
    }
};

export const transfer = (account, amount) => async (dispatch, getState) => {
    const { signer, contracts, chain } = BlockChain.getInfo();
    const energy = cloneDeep(getState().energy);

    try {
        if (!signer) throw new Error('Please connect your wallet');
        if (chain !== CHAIN) throw new Error(`Connected to an unsupported chain, please connect to ${CHAIN_NAME}`);

        const tx = await contracts.energyToken.connect(signer).transferEnergy(account, amount);
        await tx.wait();

        dispatch(getEnergy());

        return dispatch({
            payload: energy,
            type: SET_ENERGY,
        });
    } catch (error) {
        dispatch({
            type: ENERGY_ERROR,
        });
        throw error;
    }
};

export const set = (payload) => (dispatch, getState) => {
    return dispatch({
        payload,
        type: SET_ENERGY,
    });
};
