import { formatPoolInfo, formatUserShareDivs, stakeFormatter } from 'utils/bc-formatter';
import BlockChain from 'web3/Blockchain';
import { SET_STAKING, STAKING_ERROR } from '../reducers/staking';
import { DECIMALS, CHAIN_NAME, CHAIN } from 'utils/constants';
import { cloneDeep } from 'lodash';
import { ethers } from 'ethers';
import { get as getBalances } from './balances';
import { get as getEnergy } from './energy';

export const get = () => async (dispatch, getState) => {
    const { address, contracts } = BlockChain.getInfo();

    try {
        const poolInfo = (await contracts.staking?.getPoolInfo?.()) ?? {};
        const stakes = (await contracts?.reader?.listAllStakes?.(address)) ?? [];
        const divs = (await contracts?.reader?.getShareDivs?.(address)) ?? [];
        const totalSupply = (await contracts?.token?.totalSupply()) ?? 0;
        const treasurySupply = (await contracts?.token?.balanceOf(contracts.treasuryV2?.address)) ?? 0;
        let approval = 0;
        if (contracts.staking) {
            approval = await contracts.token.allowance(address, contracts.staking?.address);
        }

        return dispatch({
            payload: {
                live: Boolean(contracts.staking),
                totalSupply: totalSupply?.formatUnits?.() ?? 0,
                treasurySupply: treasurySupply?.formatUnits?.() ?? 0,
                poolInfo: formatPoolInfo(poolInfo),
                stakes: stakes.map((stake) => stakeFormatter(stake)),
                approval: approval?.formatUnits?.() ?? 0,
                divs: formatUserShareDivs(divs),
            },
            type: SET_STAKING,
        });
    } catch (error) {
        console.log(error);
    }
};

export const updatePool = () => async (dispatch) => {
    const { signer, contracts, chain } = BlockChain.getInfo();

    try {
        if (!signer) throw new Error('Please connect your wallet');
        if (chain !== CHAIN) throw new Error(`Connected to an unsupported chain, please connect to ${CHAIN_NAME}`);

        const tx = await contracts.staking.connect(signer).updatePool();
        await tx.wait(); // tx.hash

        return dispatch(get());
    } catch (error) {
        dispatch({
            type: STAKING_ERROR,
        });

        throw error;
    }
};

export const withdrawRewards = (id) => async (dispatch, getState) => {
    const { signer, address, contracts, chain } = BlockChain.getInfo();

    const staking = cloneDeep(getState().staking);

    try {
        if (!signer) throw new Error('Please connect your wallet');
        if (chain !== CHAIN) throw new Error(`Connected to an unsupported chain, please connect to ${CHAIN_NAME}`);

        const tx = await contracts.staking.connect(signer).withdrawRewards(id);
        await tx.wait(); // tx.hash

        const stakes = await contracts.reader.listAllStakes(address);
        staking.stakes = stakes.map((stake) => stakeFormatter(stake));
        return dispatch({
            payload: staking,
            type: SET_STAKING,
        });
    } catch (error) {
        dispatch({
            type: STAKING_ERROR,
        });

        throw error;
    }
};

export const withdraw = (id) => async (dispatch, getState) => {
    const { signer, address, contracts, chain } = BlockChain.getInfo();

    const staking = cloneDeep(getState().staking);

    try {
        if (!signer) throw new Error('Please connect your wallet');
        if (chain !== CHAIN) throw new Error(`Connected to an unsupported chain, please connect to ${CHAIN_NAME}`);

        const tx = await contracts.staking.connect(signer).withdraw(id);
        await tx.wait(); // tx.hash

        const stakes = await contracts.reader.listAllStakes(address);
        staking.stakes = stakes.map((stake) => stakeFormatter(stake));
        return dispatch({
            payload: staking,
            type: SET_STAKING,
        });
    } catch (error) {
        dispatch({
            type: STAKING_ERROR,
        });

        throw error;
    }
};

export const withdrawDivToken = (tokenAddress) => async (dispatch, getState) => {
    const { signer, address, contracts, chain } = BlockChain.getInfo();

    const staking = cloneDeep(getState().staking);

    try {
        if (!signer) throw new Error('Please connect your wallet');
        if (chain !== CHAIN) throw new Error(`Connected to an unsupported chain, please connect to ${CHAIN_NAME}`);

        const tx = await contracts.userShares.connect(signer).withdrawDivTokens(tokenAddress);
        await tx.wait(); // tx.hash

        const divs = (await contracts?.reader?.getShareDivs?.(address)) ?? [];
        staking.divs = formatUserShareDivs(divs);
        return dispatch({
            payload: staking,
            type: SET_STAKING,
        });
    } catch (error) {
        dispatch({
            type: STAKING_ERROR,
        });

        throw error;
    }
};

export const deposit = (amount, length) => async (dispatch, getState) => {
    const { signer, address, contracts, chain } = BlockChain.getInfo();

    const staking = cloneDeep(getState().staking);
    const units = DECIMALS.nxn;

    try {
        if (!signer) throw new Error('Please connect your wallet');
        if (chain !== CHAIN) throw new Error(`Connected to an unsupported chain, please connect to ${CHAIN_NAME}`);

        const tx = await contracts.staking.connect(signer).deposit(ethers.utils.parseUnits(amount, units), length);
        await tx.wait(); // tx.hash

        const stakes = await contracts.reader.listAllStakes(address);
        staking.stakes = stakes.map((stake) => stakeFormatter(stake));

        await dispatch(getBalances());
        return dispatch({
            payload: staking,
            type: SET_STAKING,
        });
    } catch (error) {
        dispatch({
            type: STAKING_ERROR,
        });

        throw error;
    }
};

export const rename = (stakeId, name) => async (dispatch, getState) => {
    const { signer, contracts, chain } = BlockChain.getInfo();

    const staking = cloneDeep(getState().staking);

    try {
        if (!signer) throw new Error('Please connect your wallet');
        if (chain !== CHAIN) throw new Error(`Connected to an unsupported chain, please connect to ${CHAIN_NAME}`);

        const tx = await contracts.staking.connect(signer).setStakeName(name, stakeId);
        await tx.wait(); // tx.hash

        staking.stakes.forEach((stake) => {
            if (stake.id === stakeId) {
                stake.data.name = name;
            }
        });

        await dispatch(getEnergy());
        return dispatch({
            payload: staking,
            type: SET_STAKING,
        });
    } catch (error) {
        dispatch({
            type: STAKING_ERROR,
        });

        throw error;
    }
};

export const split = (stakeId, percent) => async (dispatch, getState) => {
    const { signer, address, contracts, chain } = BlockChain.getInfo();

    const staking = cloneDeep(getState().staking);

    try {
        if (!signer) throw new Error('Please connect your wallet');
        if (chain !== CHAIN) throw new Error(`Connected to an unsupported chain, please connect to ${CHAIN_NAME}`);

        const tx = await contracts.staking.connect(signer).splitStake(stakeId, percent);
        await tx.wait(); // tx.hash

        const stakes = await contracts.reader.listAllStakes(address);
        staking.stakes = stakes.map((stake) => stakeFormatter(stake));

        await dispatch(getEnergy());
        return dispatch({
            payload: staking,
            type: SET_STAKING,
        });
    } catch (error) {
        dispatch({
            type: STAKING_ERROR,
        });

        throw error;
    }
};

export const transfer = (stakeId, to) => async (dispatch, getState) => {
    const { signer, address, contracts, chain } = BlockChain.getInfo();

    const staking = cloneDeep(getState().staking);

    try {
        if (!signer) throw new Error('Please connect your wallet');
        if (chain !== CHAIN) throw new Error(`Connected to an unsupported chain, please connect to ${CHAIN_NAME}`);

        const isApproved = await contracts.stakeToken.isApprovedForAll(address, contracts.transferToken.address);
        if (!isApproved) {
            const approveTx = await contracts.stakeToken.connect(signer).setApprovalForAll(contracts.transferToken.address, true);
            await approveTx.wait();
        }
        const tx = await contracts.transferToken.connect(signer).transferERC721Token(contracts.stakeToken.address, address, to, stakeId);
        await tx.wait(); // tx.hash

        const stakes = await contracts.reader.listAllStakes(address);
        staking.stakes = stakes.map((stake) => stakeFormatter(stake));

        await dispatch(getEnergy());
        return dispatch({
            payload: staking,
            type: SET_STAKING,
        });
    } catch (error) {
        dispatch({
            type: STAKING_ERROR,
        });

        throw error;
    }
};

export const approve = () => async (dispatch, getState) => {
    const { signer, contracts, chain } = BlockChain.getInfo();

    const staking = cloneDeep(getState().staking);
    const units = DECIMALS.nxn;

    try {
        if (!signer) throw new Error('Please connect your wallet');
        if (chain !== CHAIN) throw new Error(`Connected to an unsupported chain, please connect to ${CHAIN_NAME}`);

        const tx = await contracts.token.connect(signer).approve(contracts.staking.address, ethers.constants.MaxUint256);
        const finished = await tx.wait(); // tx.hash
        const event = finished.events.find((e) => e.event === 'Approval');

        if (event) {
            staking.approval = event.args.value.formatUnits(units);
        }

        return dispatch({
            payload: staking,
            type: SET_STAKING,
        });
    } catch (error) {
        dispatch({
            type: STAKING_ERROR,
        });

        throw error;
    }
};

export const set = (payload) => (dispatch, getState) => {
    return dispatch({
        payload,
        type: SET_STAKING,
    });
};
