import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Navigation from 'components/Navbar/Navigation';
/** Routes */
import Dashboard from 'containers/Dashboard/Dashboard';
import EnergyToken from 'containers/EnergyToken/EnergyToken';
import Mining from 'containers/Mining/Mining';
import Bonds from 'containers/Bonds/Bonds';
import isTestnet from 'utils/is-testnet';

const NavRoute = (props) => {
    const testnet = isTestnet();

    return (
        <Navigation>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/energy-token" element={<EnergyToken />} />
                <Route path="/mine" element={<Mining />} />
                <Route path="/adoption" element={<Bonds />} />
            </Routes>
        </Navigation>
    );
};

const Router = (props) => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<NavRoute />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
