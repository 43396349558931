import { combineReducers } from 'redux';

import user from './user';
import staking from './staking';
import snackbar from './snackbar';
import balances from './balances';
import energy from './energy';
import dashboard from './dashboard';
import bonds from './bonds';

const rootReducer = combineReducers({
    bonds,
    dashboard,
    user,
    snackbar,
    staking,
    balances,
    energy,
});

export default rootReducer;
