import React, { useEffect, useState } from 'react';
// Modules
// UI
import { Alert, Box, Button, Container, Grid, IconButton, TextField, Typography, useTheme } from '@mui/material';
// Styles
import Background from 'assets/images/backgrounds/Hero-purple.png';
import useStyles from './styles';
import { ContractContext } from 'web3/WagmiListener';
import { useDispatch, useSelector } from 'react-redux';
import { approve, buy, get as getEnergy, transfer } from 'redux/actions/energy';
import EnergyCard from 'components/Cards/EnergyCard/EnergyCard';
import WalletCard from 'components/Cards/WalletCard/WalletCard';
import Card from 'components/Cards/Card/Card';
import NumberFormatField from 'components/Fields/NumberFormatField';
import EnergyIcon from 'assets/images/icons/Energy_Symbol.png';
import { TOKEN_LOGOS } from 'utils/constants';
import clsx from 'clsx';
import { LoadingButton } from '@mui/lab';
import { setSnackbar } from 'redux/actions/snackbar';
import { metamaskErrorWrap } from 'utils/metamask-error';
import { ethers } from 'ethers';

const ACTIONS = {
    BUY: 'BUY',
    TRANSFER: 'TRANSFER',
};

const TOKENS = {
    usdt: TOKEN_LOGOS.usdt,
    usdc: TOKEN_LOGOS.usdc,
    dai: TOKEN_LOGOS.dai,
};

export default function EnergyToken({ initialAsset = 'usdc' }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [action, setAction] = useState(ACTIONS.BUY);
    const { initialized } = React.useContext(ContractContext);
    const { energy, balances } = useSelector(({ energy, balances }) => ({ energy, balances }));
    const [amount, setAmount] = useState({ value: '', error: false });
    const [address, setAddress] = useState({ value: '', error: false });
    const [asset, setAsset] = useState(initialAsset);
    const [confirming, setConfirming] = useState(false);
    const needsApproval = parseFloat(amount.value) > energy.approvals?.[asset];

    useEffect(() => {
        if (initialized) dispatch(getEnergy());
    }, [initialized]);

    useEffect(() => {
        handleSetAmount(amount.value);
    }, [asset]);

    function handleSetAmount(value) {
        if (parseFloat(balances[asset]) < parseFloat(value)) {
            return setAmount({ value: value, error: 'Insufficient Balance' });
        }

        setAmount({ value: value, error: false });
    }

    function handleSetAddress(e) {
        if (!ethers.utils.isAddress(address.value)) {
            return setAddress({ value: e.target.address, error: 'Invalid address' });
        }

        setAddress({ value: e.target.value, error: false });
    }

    function handleAssetChanged(asset) {
        setAsset(asset);
    }

    function handleConfirm() {
        if (action === ACTIONS.BUY) handleBuy();
        else if (action === ACTIONS.TRANSFER) handleTransfer();
    }

    async function handleBuy() {
        setConfirming(true);
        try {
            if (parseFloat(amount.value || 0) <= 0) {
                throw new Error('Input a number > 0');
            }

            if (needsApproval) {
                await dispatch(approve(asset));
            }

            await dispatch(buy(amount.value, asset));

            dispatch(setSnackbar({ message: 'Thanks for purchasing energy tokens! Your balance should be updated!' }));
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: metamaskErrorWrap(error) }));
        }
        setConfirming(false);
    }

    async function handleTransfer() {
        setConfirming(true);
        try {
            if (parseFloat(amount.value || 0) <= 0) {
                throw new Error('Input a number > 0');
            }

            if (!ethers.utils.isAddress(address.value)) {
                throw new Error('Invalid address');
            }

            await dispatch(transfer(address.value, amount.value));

            dispatch(setSnackbar({ message: `Energy transfered to ${address.value}` }));
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: metamaskErrorWrap(error) }));
        }
        setConfirming(false);
    }

    const cost = (amount?.value ?? 0) * (energy?.pricePerEnergy ?? 0);

    return (
        <>
            {!energy.live && (
                <Alert severity="error" icon={false}>
                    <Typography variant="h6">Energy Tokens are not currently live, please check back later.</Typography>
                </Alert>
            )}
            <Box className={classes.root}>
                <img src={Background} className={classes.background} />
                <Container maxWidth="lg" className={classes.container}>
                    <Card color="purpleRoast" className={classes.content}>
                        <Box className={classes.contentHeader}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <EnergyCard actions={false} sx={{ height: '100%' }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <WalletCard color={asset} balance={asset} />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className={classes.contentDivider} />
                        <Box className={classes.contentBody}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={6}>
                                    <Card color="deepBlack" className={classes.inputBox}>
                                        <Box className={classes.inputHeader}>
                                            <Typography variant="h4" fontWeight={'600'}>
                                                Buy & Transfer Energy Tokens
                                            </Typography>
                                        </Box>
                                        <Box className={classes.inputBody}>
                                            <Box className={classes.tokens} sx={{ marginBottom: 1 }}>
                                                {Object.keys(TOKENS).map((key) => {
                                                    const img = TOKENS[key];

                                                    return (
                                                        <IconButton
                                                            key={key}
                                                            onClick={() => handleAssetChanged(key)}
                                                            className={clsx(classes.token, {
                                                                [classes.activeToken]: asset === key,
                                                            })}
                                                        >
                                                            <img src={img} className={classes.token} />
                                                        </IconButton>
                                                    );
                                                })}
                                            </Box>

                                            <Box className={classes.inputActions} sx={{ marginBottom: 2 }}>
                                                <Button
                                                    fullWidth
                                                    variant={action === ACTIONS.BUY ? 'contained' : 'outlined'}
                                                    onClick={() => setAction(ACTIONS.BUY)}
                                                    color="secondary"
                                                >
                                                    BUY
                                                </Button>
                                                <Button
                                                    fullWidth
                                                    variant={action === ACTIONS.TRANSFER ? 'contained' : 'outlined'}
                                                    onClick={() => setAction(ACTIONS.TRANSFER)}
                                                    color="secondary"
                                                >
                                                    TRANSFER
                                                </Button>
                                            </Box>
                                            <Box className={classes.inputFields} sx={{ marginBottom: 2 }}>
                                                <TextField
                                                    color={asset}
                                                    fullWidth
                                                    label="Input an amount"
                                                    placeholder="100"
                                                    error={Boolean(amount.error)}
                                                    helperText={amount.error && amount.error}
                                                    value={amount.value}
                                                    onChange={(e) => handleSetAmount(e.target.value)}
                                                    InputProps={{
                                                        inputComponent: NumberFormatField,
                                                    }}
                                                />
                                                {action === ACTIONS.BUY && (
                                                    <Typography sx={{ color: theme.palette[asset].main }}>
                                                        Total Cost: {cost.numberWithCommas(0)} {asset.toUpperCase()}
                                                    </Typography>
                                                )}
                                                {action === ACTIONS.TRANSFER && (
                                                    <TextField
                                                        color="secondary"
                                                        fullWidth
                                                        label="Input an address"
                                                        placeholder="0x0000...00000"
                                                        error={Boolean(address.error)}
                                                        helperText={address.error && address.error}
                                                        value={address.value}
                                                        onChange={handleSetAddress}
                                                    />
                                                )}
                                            </Box>

                                            <LoadingButton
                                                disabled={amount.error}
                                                loading={confirming}
                                                fullWidth
                                                variant="contained"
                                                onClick={handleConfirm}
                                            >
                                                <Typography variant="font2">{needsApproval ? 'Approve' : 'Confirm'}</Typography>
                                            </LoadingButton>
                                        </Box>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box className={classes.infoItem}>
                                        <Typography variant="h6" fontWeight={'700'}>
                                            PRICE PER ENERGY
                                        </Typography>
                                        <Box className={classes.infoImageContainer}>
                                            <img src={EnergyIcon} />
                                        </Box>
                                        <Typography
                                            fontWeight={'700'}
                                            variant="h6"
                                            sx={{ minWidth: '88.69px', textAlign: 'right', color: theme.palette[asset].main }}
                                        >
                                            1 {asset.toUpperCase()}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.infoItem}>
                                        <Typography variant="h6">ENERGY PER RENAME</Typography>
                                        <Box className={classes.infoImageContainer}>
                                            <img src={EnergyIcon} />
                                        </Box>
                                        <Typography variant="h6">{energy?.energyPerRename ?? 1} ENERGY</Typography>
                                    </Box>
                                    <Box className={classes.infoItem}>
                                        <Typography variant="h6">ENERGY PER TRANSFER</Typography>
                                        <Box className={classes.infoImageContainer}>
                                            <img src={EnergyIcon} />
                                        </Box>
                                        <Typography variant="h6">{energy?.energyPerTransfer ?? 6} ENERGY</Typography>
                                    </Box>
                                    <Box className={classes.infoItem}>
                                        <Typography variant="h6">ENERGY PER STAKE-SPLIT</Typography>
                                        <Box className={classes.infoImageContainer}>
                                            <img src={EnergyIcon} />
                                        </Box>
                                        <Typography variant="h6">{energy?.energyPerSplit ?? 4} ENERGY</Typography>
                                    </Box>
                                    <Box className={classes.infoItem}>
                                        <Typography variant="h6">ENERGY PER MINER</Typography>
                                        <Box className={classes.infoImageContainer}>
                                            <img src={EnergyIcon} />
                                        </Box>
                                        <Typography variant="h6">{energy?.energyPerMiner ?? 5} ENERGY</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </>
    );
}
