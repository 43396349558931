import { FaFlask } from 'react-icons/fa';
import { RxDashboard } from 'react-icons/rx';
import { GiAmplitude, GiMineWagon, GiRollingEnergy } from 'react-icons/gi';
import isTestnet from 'utils/is-testnet';

export const items = [
    {
        Icon: RxDashboard,
        text: isTestnet() ? 'Testnet Dashboard' : 'Dashboard',
        pathname: isTestnet() ? '/' : '/',
        hidden: isTestnet() ? false : false,
    },
    {
        Icon: GiAmplitude,
        text: 'Adoption Amplifier',
        pathname: '/adoption',
        hidden: isTestnet() ? false : true,
    },
    {
        Icon: GiMineWagon,
        text: 'eMining',
        pathname: '/mine',
        hidden: isTestnet() ? false : true,
    },
    {
        Icon: GiRollingEnergy,
        text: 'Energy',
        pathname: '/energy-token',
        hidden: isTestnet() ? false : true,
    },

    {
        Icon: FaFlask,
        text: 'Testnet',
        external: true,
        pathname: 'https://mine-dev.nxn.network',
        hidden: !isTestnet() ? false : true,
    },
    {
        Icon: FaFlask,
        text: 'Mainnet',
        pathname: 'https://mine.nxn.network',
        hidden: isTestnet() ? false : true,
    },
];
