import { bondingOverallFormatter, stakingOverallFormatter } from 'utils/bc-formatter';
import { DASHBOARD_ERROR, SET_DASHBOARD } from '../reducers/dashboard';
import axios from 'axios';
import gateway from 'integrations/gateway';

export const get = () => async (dispatch) => {
    try {
        const [overalls] = await Promise.all([axios.get(`${gateway.endpoint}/v1/stats`)]);

        return dispatch({
            payload: {
                overalls: {
                    staking: overalls.data.staking && stakingOverallFormatter(overalls.data.staking),
                    bonding: overalls.data.bonding && bondingOverallFormatter(overalls.data.bonding),
                },
            },
            type: SET_DASHBOARD,
        });
    } catch (error) {
        console.log(error);
        dispatch({
            type: DASHBOARD_ERROR,
        });
    }
};

export const set = (payload) => (dispatch, getState) => {
    return dispatch({
        payload,
        type: SET_DASHBOARD,
    });
};
