import * as React from 'react';
import { Alert, Box, Hidden, Typography } from '@mui/material';
import useStyles from './styles';
import SideNav from './SideNav/SideNav';
import TopNav from './TopNav/TopNav';
import { useSelector } from 'react-redux';
import { useNetwork } from 'wagmi';
import { CHAIN, CHAIN_NAME } from 'utils/constants';
import isTestnet from 'utils/is-testnet';
import isAllowedChain from 'utils/is-allowed-chain';

export default function Navigation({ children }) {
    const classes = useStyles();
    const { staking } = useSelector(({ staking }) => ({ staking }));
    const { chain } = useNetwork();

    return (
        <Box className={classes.root}>
            <Hidden mdDown>
                <SideNav />
            </Hidden>
            <Hidden mdUp>
                <TopNav />
            </Hidden>
            <Box component="main" className={classes.main}>
                {isTestnet() && (
                    <Alert severity="info" sx={{ zIndex: 99, position: 'relative' }}>
                        Currently <span className="bold">seconds in a day is set to {'14,400'} seconds so things might go faster</span> than
                        you are expecting!
                        <br />
                        You can get testnet ETH from our discord faucet!{' '}
                        <a style={{ color: 'white' }} href="https://discord.gg/2s7n8sYUtE">
                            https://discord.gg/2s7n8sYUtE
                        </a>{' '}
                    </Alert>
                )}
                {!isTestnet() && (
                    <Alert severity="info" sx={{ zIndex: 99, position: 'relative' }}>
                        Main net is not yet live. Check out our testnet!{' '}
                        <a style={{ color: 'white' }} href="https://mine-dev.nxn.network/">
                            https://mine-dev.nxn.network/
                        </a>{' '}
                    </Alert>
                )}
                {!isAllowedChain(chain?.id) && (
                    <Alert severity="error" icon={false} sx={{ zIndex: 99, position: 'relative' }}>
                        <Typography variant="h6">Please connect to the {CHAIN_NAME} network.</Typography>
                    </Alert>
                )}
                {children}
            </Box>
        </Box>
    );
}
