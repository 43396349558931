import React, { useState } from 'react';
import { Box, Button, Checkbox, DialogActions, FormControlLabel, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import useStyles from './styles';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import { metamaskErrorWrap } from 'utils/metamask-error';
import { rename } from 'redux/actions/staking';

export default function Rename({ onClose, stake }) {
    const classes = useStyles();
    const theme = useTheme();
    const [name, setName] = useState('');
    const [understood, setUnderstood] = useState(false);
    const { staking } = useSelector(({ staking }) => ({ staking }));
    const [renaming, setRenaming] = useState(false);
    const dispatch = useDispatch();

    async function handleSubmit() {
        try {
            setRenaming(true);
            try {
                await dispatch(rename(stake.id, name));
                dispatch(setSnackbar({ message: 'eMiner renamed!', severity: 'success' }));
                onClose?.();
            } catch (error) {
                console.log(error);
                dispatch(setSnackbar({ message: metamaskErrorWrap(error), severity: 'error' }));
            }
            setRenaming(false);
        } catch (error) {}
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.input} sx={{ marginBottom: 3 }}>
                <Typography mb={2}>
                    Set a custom name for your eMiner. This name is public and can be seen by anyone on the blockchain. It may take a few
                    minutes for the new name to be updated across the network nodes and reflected in the mining table.
                </Typography>
                <TextField
                    color="secondary"
                    className={classes.field}
                    value={name}
                    onChange={(e) => {
                        if (e.target.value.length > 32) return;
                        setName(e.target.value);
                    }}
                    label="Name your eMiner"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography>{name.length} / 32</Typography>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <Box className={classes.understood} sx={{ marginBottom: 2 }}>
                <FormControlLabel
                    control={<Checkbox value={understood} onChange={(e) => setUnderstood(e.target.checked)} />}
                    label={
                        <span>
                            I understand that{' '}
                            <span style={{ color: theme.palette.energy.main }}>{staking?.poolInfo?.energyPerRename} energy token(s)</span>{' '}
                            will be taken from my wallet
                        </span>
                    }
                />
            </Box>

            <Box className={classes.actions}>
                <Button variant="outlined" fullWidth color="secondary" onClick={onClose} autoFocus>
                    Cancel
                </Button>
                <LoadingButton
                    loading={renaming}
                    variant="contained"
                    color="secondary"
                    disabled={!understood}
                    fullWidth
                    onClick={handleSubmit}
                    autoFocus
                >
                    Submit
                </LoadingButton>
            </Box>
        </Box>
    );
}
