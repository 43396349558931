import React, { useRef, useState } from 'react';
// Modules
import { useDispatch, useSelector } from 'react-redux';
// UI
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Styles
import useStyles from './styles';
import dayjs from 'dayjs';
import { setSnackbar } from 'redux/actions/snackbar';
import { metamaskErrorWrap } from 'utils/metamask-error';
import { redeem } from 'redux/actions/bonds';
import { useConfirm } from 'material-ui-confirm';

export default function MyBonds({ sx }) {
    const classes = useStyles();
    const { bonds } = useSelector(({ bonds }) => ({ bonds }));
    const currentTime = new Date().getTime();
    const dispatch = useDispatch();
    const [redeeming, setRedeeming] = useState({});
    const confirm = useConfirm();
    const _redeeming = useRef({});

    async function handleRedeem(userBond) {
        confirm({
            description: (
                <Typography variant="font2" fontWeight={'400'}>
                    You will be penalized <span className="error">{userBond.penalties.numberWithCommas(2)} NXN</span> and receive{' '}
                    <span className="success">{userBond.payout.numberWithCommas(2)} NXN</span>.
                </Typography>
            ),
        })
            .then(async () => {
                _redeeming.current[userBond.id] = true;
                setRedeeming(_redeeming.current);
                try {
                    await dispatch(redeem(userBond.id, userBond.token.bond));
                    dispatch(setSnackbar({ message: 'Bond redeemed!', severity: 'success' }));
                } catch (error) {
                    dispatch(setSnackbar({ message: metamaskErrorWrap(error), severity: 'error' }));
                }
                delete _redeeming.current[userBond.id];
                setRedeeming(_redeeming.current);
            })
            .catch(() => {});
    }

    return (
        <Box className={classes.root} sx={sx}>
            <Typography variant="h6">My Amplifiers</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="tableHeader1">Payout</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1">Available/Penalties</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1">Vested Until</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1"></Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bonds.userBonds?.map?.((item, i) => {
                            const { info } = item;

                            const vestedUntil = (info.startTime + info.vesting) * 1000;

                            return (
                                <TableRow key={`bond-${i}`}>
                                    <TableCell>
                                        <Typography variant="font1" fontWeight="500">
                                            {info.payout.numberWithCommas(2)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="font1" fontWeight="500">
                                            <span className="success">{item.payout?.numberWithCommas?.(2)} NXN</span>
                                            {' / '}
                                            <span className="error">{item.penalties?.numberWithCommas?.(2)} NXN</span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="font1" fontWeight="500" sx={{ minWidth: '170px' }}>
                                            {dayjs(vestedUntil).format('MMM DD, YYYY hh:mm:ss a')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <LoadingButton loading={redeeming[item.id]} onClick={() => handleRedeem(item)}>
                                            Redeem
                                        </LoadingButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
