let gateway = {};
// load the correct aws config options

switch (process.env.REACT_APP_VERCEL_ENV) {
    case 'production':
        gateway.endpoint = 'https://gateway.nxn.network';
        break;
    case 'development':
    case 'preview':
        gateway.endpoint = 'https://dev.gateway.nxn.network';
        break;
    default:
        gateway.endpoint = 'https://dev.gateway.nxn.network';
        break;
}

if (process.env.REACT_APP_VERCEL_ENV !== 'production') {
}

export default gateway;
