import { makeStyles } from '@mui/styles';
import theme from 'theme';
import Texture from 'assets/images/backgrounds/mountainTextureGreen.png';

const useStyles = makeStyles(() => ({
    root: {
        padding: '12px 20px',
        borderRadius: 12,
        backgroundColor: 'black',
        borderWidth: 1,
        borderStyle: 'solid',
        // background: `url(${Texture})`,
        transition: '0.2s all ease-in',
        backgroundSize: 'cover',
        '&:hover': {
            transform: 'translateY(-3px)',
        },
    },
    walletImage: {
        width: 34,
        height: 34,
        objectFit: 'contain',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '8px',
    },
    between: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

export default useStyles;
