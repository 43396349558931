export const SET_STAKING = 'SET_STAKING';
export const STAKING_ERROR = 'STAKING_ERROR';
export const INIT = {};

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_STAKING:
            return payload;
        case STAKING_ERROR:
        default:
            return state;
    }
}

export default reducer;
