import React, { useState } from 'react';
// Modules
import { useDispatch, useSelector } from 'react-redux';
// UI
import { Box, Button, InputAdornment, Paper, TextField, Typography } from '@mui/material';
// Styles
import useStyles from './styles';
import NumberFormatField from 'components/Fields/NumberFormatField';
import { TOKEN_LOGOS } from 'utils/constants';
import { LoadingButton } from '@mui/lab';
import { setSnackbar } from 'redux/actions/snackbar';
import { metamaskErrorWrap } from 'utils/metamask-error';
import dayjs from 'dayjs';
import { approve, deposit } from 'redux/actions/bonds';

export default function BondInput({ selectedBond, sx }) {
    const classes = useStyles();
    const { bonds, balances } = useSelector(({ bonds, balances }) => ({ bonds, balances }));
    const [amount, setAmount] = useState({ value: '', error: null });
    const [bonding, setBonding] = useState(false);
    const { approval, principles, contract, info, principle } = selectedBond;
    const needsApproval = parseFloat(amount.value) > approval;
    const dispatch = useDispatch();

    function handleSetAmount(e) {
        let error = null;
        if (balances[principle] < parseFloat(e.target.value)) {
            error = `Not enough ${principle}`;
        }

        setAmount({ value: e.target.value, error });
    }

    async function handleSubmit() {
        setBonding(true);
        try {
            if (parseFloat(amount.value || 0) <= 0) {
                throw new Error('Input a number > 0');
            }

            if (needsApproval) {
                await dispatch(approve(principle, contract));
            }

            await dispatch(deposit(amount.value, principle, contract));
            dispatch(setSnackbar({ message: 'Bonded!', severity: 'success' }));
        } catch (error) {
            const errorMessage = metamaskErrorWrap(error);
            setAmount({ amount: amount.value, error: errorMessage });
            dispatch(setSnackbar({ severity: 'error', message: errorMessage }));
        }
        setBonding(false);
    }

    return (
        <Box component={Paper} className={classes.root} sx={sx}>
            <Box className={classes.stats}>
                <Box className={classes.tStat}>
                    <Typography variant="font2" fontWeight={500}>
                        Discount
                    </Typography>
                    <Typography variant="h6" fontWeight={600}>
                        {info.discount}%
                    </Typography>
                </Box>
                <Box className={classes.tStat}>
                    <Typography variant="font2" fontWeight={500}>
                        Estimated NXN
                    </Typography>
                    <Typography variant="h6" fontWeight={600}>
                        {((amount?.value ?? 0) / (bonds.price - bonds.price * (info.discount / 100))).numberWithCommas(2)}
                    </Typography>
                </Box>
                <Box className={classes.tStat}>
                    <Typography variant="font2" fontWeight={500}>
                        Price
                    </Typography>
                    <Typography variant="h6" fontWeight={600}>
                        ${(bonds.price - bonds.price * (info.discount / 100)).numberWithCommas(5)}
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.actions}>
                <TextField
                    label="Input an amount"
                    placeholder="100.0"
                    error={amount.error}
                    helperText={amount.error ? amount.error : ''}
                    value={amount.value}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit();
                        }
                    }}
                    color={principle}
                    onChange={handleSetAmount}
                    InputProps={{
                        inputComponent: NumberFormatField,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Box className={classes.principles}>
                                    {principles.map((p, i) => {
                                        return <img src={TOKEN_LOGOS[p]} key={p} />;
                                    })}
                                </Box>
                            </InputAdornment>
                        ),
                    }}
                />
                <Typography sx={{ marginTop: 1 }}>
                    Balance: {balances?.[principle]?.numberWithCommas(2)} {principle}
                </Typography>
                <Typography>Vested until: {dayjs(new Date().getTime() + info.vestingLength * 1000).format('MMM DD, YYYY')}</Typography>
                <Typography>Receive {parseInt(amount?.value * 0.05)} Energy Tokens</Typography>

                <LoadingButton onClick={handleSubmit} loading={bonding} sx={{ marginTop: 2 }} variant="contained">
                    {needsApproval ? 'Approve' : 'Deposit'}
                </LoadingButton>
            </Box>
        </Box>
    );
}
