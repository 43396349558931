import { createTheme } from '@mui/material/styles';

const { breakpoints } = createTheme({});

// Create a theme instance.
const theme = createTheme({
    palette: {
        mode: 'dark',
        deepBlack: {
            main: '#000000',
        },
        green: {
            main: '#44d786',
        },
        primary: {
            main: '#5D36FF',
        },
        secondary: {
            main: '#F5AC37',
        },
        purpleRoast: {
            main: '#110E31',
        },
        purplePink: {
            main: '#792AA3',
        },
        lightPurple: {
            main: '#c093ec',
        },
        darkPurple: {
            main: '#250c60',
        },
        charcoal: {
            main: '#475166',
        },
        usdc: {
            main: '#2774CA',
        },
        usdt: {
            main: '#4FAF95',
        },
        dai: {
            main: '#F5AC37',
        },
        energy: {
            main: '#F5AC37',
        },
        nxn: {
            main: '#5D36FF',
        },
        charcoal: {
            main: '#475166',
        },
        white: {
            main: '#fff',
        },
        pontoonBlue: {
            main: '#3d0b89',
        },
        pelorusBlue: {
            main: '#6326b2',
        },
        darkBlack: {
            main: '#0B0E1C',
        },
        lucarioBlue: {
            main: '#5d0ab5',
        },
        richRed: {
            main: '#ff1744',
        },
    },
    typography: {
        fontFamily: `Rajdhani`,

        /** specific fonts */
        large: {
            fontSize: 60,
            display: 'block',

            [breakpoints.down('md')]: {
                fontSize: 40,
            },
            [breakpoints.down('sm')]: {
                fontSize: 28,
            },
        },
        /** Normal */
        h1: {
            fontSize: 54,
            [breakpoints.down('sm')]: {
                fontSize: 36,
            },
        },
        h2: {
            fontSize: 48,
            [breakpoints.down('sm')]: {
                fontSize: 32,
            },
        },
        h3: {
            fontSize: 40,
            [breakpoints.down('sm')]: {
                fontSize: 28,
            },
        },
        h4: {
            fontSize: 32,
            [breakpoints.down('sm')]: {
                fontSize: 26,
            },
        },
        h5: {
            fontSize: 28,
            [breakpoints.down('sm')]: {
                fontSize: 22,
            },
        },
        h6: {
            fontSize: 24,
            [breakpoints.down('sm')]: {
                fontSize: 20,
            },
        },
        font1: {
            fontSize: 18,
            fontFamily: `Rajdhani`,
            display: 'block',
            [breakpoints.down('sm')]: {
                fontSize: 16,
            },
        },

        font2: {
            fontSize: 16,
            fontFamily: `Rajdhani`,
            display: 'block',
            [breakpoints.down('sm')]: {
                fontSize: 15,
            },
        },
        font3: {
            fontSize: 14,
            fontFamily: `Rajdhani`,
            display: 'block',
            [breakpoints.down('sm')]: {
                fontSize: 13,
            },
        },
        font4: {
            fontSize: 12,
            fontFamily: `Rajdhani`,
            display: 'block',
            [breakpoints.down('sm')]: {
                fontSize: 11,
            },
        },
        font5: {
            fontSize: 11,
            fontFamily: `Rajdhani`,
            display: 'block',
            [breakpoints.down('sm')]: {
                fontSize: 10,
            },
        },
    },

    components: {
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    color: 'white',
                    background: 'linear-gradient(to right, #5D36FF, #c093ec)',

                    '&:hover': {
                        opacity: 0.9,
                    },
                },
            },
        },

        MuiContainer: {
            styleOverrides: {
                root: {
                    [breakpoints.down('sm')]: {
                        paddingLeft: 8,
                        paddingRight: 8,
                    },
                },
            },
        },
    },

    gradients: {
        dashboard: 'linear-gradient(to right, #5D36FF, #c093ec)',
        purpleToLightGradient: 'linear-gradient(to right, #5D36FF, #c093ec)',
        navigation: 'linear-gradient(22deg, #5D36FF -19.64%, #5b2c89 1.79%, #1A2246 35.64%, #161C38 44.34%)',
    },
});

export default theme;
