import * as React from 'react';
import { Link } from 'react-router-dom';
import Card from 'components/Cards/Card';
import { Box, Button, Typography } from '@mui/material';

import useStyles from './styles';

export default function BondingCard({ sx }) {
    const classes = useStyles();

    return (
        <Card color="darkBlack" border="pelorusBlue" className={classes.root} sx={sx}>
            <Box className={classes.sectionTitleBox} sx={{ marginBottom: 2 }}>
                <Typography variant="h4" className={classes.cardText} sx={{ fontWeight: '600' }}>
                    Adoption Amplifier
                </Typography>
            </Box>
            <Box className={classes.sectionBox} sx={{ marginBottom: 2 }}>
                <Typography variant="font1">
                    Buy $NXN at a 5% discount & get 5% back in Energy! This is a limited time offer for the first 100 days!
                </Typography>
            </Box>
            {/* <Box className={classes.sectionBox}>
                <Typography>Some text here Some text here Some text here Some text here Some text here </Typography>
            </Box> */}
            <Button component={Link} to="/adoption" variant="contained" sx={{ marginTop: 3 }}>
                Amplify your portfolio!
            </Button>
        </Card>
    );
}
