import React, { useState } from 'react';
// Modules
import { useSelector } from 'react-redux';
// UI
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
// Styles
import useStyles from './styles';

import { SECONDS_IN_DAY, TOKEN_LOGOS } from 'utils/constants';
import clsx from 'clsx';
import isTestnet from 'utils/is-testnet';

export default function AvailableBonds({ sx, onBondSelected }) {
    const classes = useStyles();
    const { dashboard, bonds } = useSelector(({ dashboard, bonds }) => ({ dashboard, bonds }));
    const [selectedBond, setSelectedBond] = useState(null);

    function handleBondSelected(b) {
        setSelectedBond(b);
        onBondSelected?.(b);
    }

    return (
        <Box className={classes.root} sx={sx}>
            <Typography variant="h6">Available Amplifiers</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="tableHeader1">Principal</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1">Price</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1">Discount</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1">Vesting Length</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bonds.items?.map?.((item, i) => {
                            const { principles, info } = item;

                            return (
                                <TableRow
                                    className={clsx(classes.row, {
                                        [classes.activeRow]: i === selectedBond,
                                    })}
                                    key={`bond-${i}`}
                                    onClick={() => handleBondSelected(i)}
                                >
                                    <TableCell>
                                        <Box className={classes.principles}>
                                            {principles.map((p, i) => {
                                                return <img src={TOKEN_LOGOS[p]} key={p} />;
                                            })}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="font1" fontWeight="500">
                                            ${(bonds.price - bonds.price * (info.discount / 100)).numberWithCommas(7)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="font1" fontWeight="500">
                                            {info.discount}%
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="font1" fontWeight="500">
                                            {(info.vestingLength / SECONDS_IN_DAY).numberWithCommas(2)} Days
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
