import * as React from 'react';
import { Box, useTheme } from '@mui/material';
import clsx from 'clsx';
import useStyles from './styles';

export default function Card({ color = 'deepBlack', border, gradient, hover, opacity = 1, className, children, sx, ...props }) {
    const theme = useTheme();
    const classes = useStyles({ color, gradient, opacity, ...props });

    const cardColor = theme.palette[color]?.main;
    const cardGradient = theme?.gradients[color];
    const borderColor = theme.palette[border]?.main || 'transparent';

    return (
        <Box
            className={clsx(classes.root, {
                [classes.borderCard]: border,
                [classes.borderGradient]: gradient,
                [classes.hoverCard]: hover,
                [className]: className,
            })}
            sx={{
                backgroundColor: `${cardColor}${Math.round(opacity * 255).toString(16)}`,
                background: cardGradient,
                borderColor: borderColor,
                ...sx,
            }}
            {...props}
        >
            {children}
        </Box>
    );
}
