import * as React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
// MUI
import { IconButton, Box, Button, Drawer, List, ListItem, Typography } from '@mui/material';
// images
import Logo from 'assets/images/logos/logo-outline-purple.png';
import { Close } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
// styles
import useStyles from './styles';
import { items } from '../items';
import { useWeb3Modal } from '@web3modal/react';
import { useAccount, useBalance } from 'wagmi';

export default function MiniDrawer({ isOpen, onClose, onLogout }) {
    const { user } = useSelector(({ user }) => ({ user }));
    const classes = useStyles();
    const location = useLocation();

    const { open } = useWeb3Modal();
    const { isConnected, address } = useAccount();
    const { data } = useBalance({ address });

    React.useEffect(() => {
        onClose();
    }, [location.pathname]);

    return (
        <Drawer anchor={'right'} open={isOpen} classes={{ paper: classes.drawer }}>
            <Box role="presentation" className={classes.drawerBox}>
                <Box className={classes.drawerHeader}>
                    <img src={Logo} className={classes.logo} />
                    <IconButton onClick={onClose}>
                        <Close size={16} />
                    </IconButton>
                </Box>

                <Box className={classes.drawerItems}>
                    {items.map(({ Icon, external, hidden, ...item }, i) => {
                        if (hidden) return null;
                        if (external) {
                            return (
                                <React.Fragment key={`item-${i}`}>
                                    <Box
                                        className={clsx(classes.drawerItem, 'links', {
                                            [classes.itemActive]: location.pathname === item.pathname,
                                        })}
                                        component={'a'}
                                        target="_blank"
                                        href={item.pathname}
                                        key={`draweritem-${i}`}
                                    >
                                        <Icon />
                                        <Typography variant="font1" sx={{ fontWeight: '500' }}>
                                            {item.text}
                                        </Typography>
                                    </Box>
                                    {i !== items.length - 1 && <Box className={classes.divider} />}
                                </React.Fragment>
                            );
                        }

                        return (
                            <React.Fragment key={`item-${i}`}>
                                <Box
                                    className={clsx(classes.drawerItem, 'links', {
                                        [classes.itemActive]: location.pathname === item.pathname,
                                    })}
                                    component={Link}
                                    to={item.pathname}
                                    key={`draweritem-${i}`}
                                >
                                    <Icon />
                                    <Typography variant="font1" sx={{ fontWeight: '500' }}>
                                        {item.text}
                                    </Typography>
                                </Box>
                                {i !== items.length - 1 && <Box className={classes.divider} />}
                            </React.Fragment>
                        );
                    })}
                </Box>
            </Box>
        </Drawer>
    );
}
