import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        color: 'inherit',
        padding: theme.spacing(3),
        textDecoration: 'none',
    },
    sectionBox: {
        display: 'flex',
        flexDirection: 'column',
    },
    sectionTitleBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    actions: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
}));

export default useStyles;
