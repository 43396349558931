import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {},
    paper: {
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% - 24px)`,
            margin: '12px',
        },
    },
    tabs: {
        margin: '0px 24px',
    },
}));

export default useStyles;
