import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        borderRadius: '12px',
        overflow: 'hidden',
    },
    borderCard: {
        borderWidth: '1px',
        borderStyle: 'solid',
    },

    hover: {
        transition: '0.2s all ease-in',
        '&:hover': {
            transform: 'translateY(-3px)',
        },
    },
}));

export default useStyles;
