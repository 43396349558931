import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    root: {
        padding: '12px 20px',
        borderRadius: 12,
        backgroundColor: 'black',
        borderWidth: 1,
        borderStyle: 'solid',
        // background: `url(${Texture})`,
        transition: '0.2s all ease-in',
        backgroundSize: 'cover',
        '&:hover': {
            transform: 'translateY(-3px)',
        },
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textAlign: 'center',
        gap: '8px',
    },
    content: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        textAlign: 'center',
        gap: '8px',
    },
}));

export default useStyles;
