import React, { useState } from 'react';
// Modules
// UI
import { Alert, Box, Hidden, IconButton, Paper, Slider, TextField, Typography } from '@mui/material';
import { TOKEN_LOGOS } from 'utils/constants';
// Styles
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormatField from 'components/Fields/NumberFormatField';
import { LoadingButton } from '@mui/lab';
import Card from 'components/Cards/Card/Card';
import dayjs from 'dayjs';
import { getShares } from 'utils/get-values';
import { approve, deposit } from 'redux/actions/staking';
import { setSnackbar } from 'redux/actions/snackbar';
import { metamaskErrorWrap } from 'utils/metamask-error';
import { InfoOutlined } from '@mui/icons-material';

export default function MineInput({ sx }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [deposting, setDepositing] = useState(false);
    const [amount, setAmount] = useState({ value: '', error: false });
    const [days, setDays] = useState({ value: '15', error: false });
    const { staking, balances } = useSelector(({ staking, balances }) => ({ staking, balances }));
    const needsApproval = parseFloat(amount.value) > staking.approval;

    function handleSetAmount(e) {
        let error = null;
        if (balances.nxn < parseFloat(e.target.value)) {
            error = `Not enough NXN`;
        }

        setAmount({ value: e.target.value, error });
    }

    async function handleSubmit() {
        setDepositing(true);
        try {
            if (parseFloat(amount.value || 0) < (staking?.poolInfo?.minStakeAmount ?? 0)) {
                throw new Error(`Input a number > ${staking?.poolInfo?.minStakeAmount ?? 0}`);
            }
            if (parseInt(days.value) < 15) {
                throw new Error(`Duration must be between 15 and 5475`);
            }

            if (needsApproval) {
                await dispatch(approve());
            }

            await dispatch(deposit(amount.value, days.value));
            dispatch(setSnackbar({ message: 'Deposited NXN for mining!', severity: 'success' }));
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: metamaskErrorWrap(error) }));
        }
        setDepositing(false);
    }

    const endTime = new Date().getTime() + parseInt(days.value) * (staking?.poolInfo?.secondsInDay ?? 86400) * 1000;
    const shares = getShares(
        parseFloat(amount.value || 0),
        parseInt(days.value),
        staking?.poolInfo?.bonusSharesPeriod ?? 1,
        staking?.poolInfo?.bonusSharesPerPeriod ?? 0,
    );

    // amount / totalStaked totalSupply * apr
    const poolOwned = shares / (staking?.poolInfo?.totalShares || 1);
    const totalSupply = (staking?.poolInfo?.pooledTokens ?? 0) + (staking?.totalSupply ?? 0) - (staking?.treasurySupply ?? 0);
    const payoutPerYear = (totalSupply * staking?.poolInfo?.currentApr) / 100;
    const payoutForShares = poolOwned * payoutPerYear;
    const estimatedApr = (payoutForShares / parseFloat(amount.value || 0)) * 100;

    const currentTime = new Date().getTime() / 1000;
    const day = (currentTime - (staking?.poolInfo?.startTime ?? currentTime)) / (staking?.poolInfo?.secondsInDay ?? 86400);

    return (
        <Card component={Paper} className={classes.root} sx={sx}>
            <Box className={classes.header}>
                <Typography variant="h6">Mining Input</Typography>
                <Typography variant="font1">Day: {staking?.live ? day.numberWithCommas(0) : '-'}</Typography>
            </Box>
            <Alert severity="info">
                Estimated APR is based on the total shares currently in the pool. This number <span className="bold">may not</span> be
                accurate.
            </Alert>
            <Box className={classes.energyInfo}>
                <Typography>Currently it cost {staking?.poolInfo?.energyPerStake} energy</Typography>
                <img src={TOKEN_LOGOS.energy} />
                <Typography>to start a miner.</Typography>
            </Box>
            <Box className={classes.stats}>
                <Box className={classes.stat}>
                    <Typography variant="font2">Shares</Typography>
                    <Typography variant="h6">{shares.numberWithCommas(2)}</Typography>
                </Box>
                <Box className={classes.stat}>
                    <Typography variant="font2">End Time</Typography>
                    <Typography variant="h6">{dayjs(endTime).format('MMM DD YYYY')}</Typography>
                </Box>
                <Box className={classes.stat}>
                    <Box className={classes.row}>
                        <Typography variant="font2">Estimated APR</Typography>
                        <IconButton component="a" target="_blank" href="https://docs.nxn.network/documentation/mining-calculator">
                            <InfoOutlined sx={{ fontSize: 18 }} />
                        </IconButton>
                    </Box>
                    <Typography variant="h6">{Number.isNaN(estimatedApr) ? '-' : `${estimatedApr?.numberWithCommas?.(0)}%`}</Typography>
                </Box>
            </Box>

            <Box className={classes.fieldRow} sx={{ marginY: 1 }}>
                <TextField
                    color="secondary"
                    fullWidth
                    label="Input an amount"
                    placeholder="100.0"
                    error={Boolean(amount.error)}
                    helperText={
                        amount.error ? amount.error : `Min deposit amount ${staking.poolInfo?.minStakeAmount?.numberWithCommas?.(0)} NXN`
                    }
                    value={amount.value}
                    onChange={handleSetAmount}
                    InputProps={{
                        inputComponent: NumberFormatField,
                    }}
                />
                <TextField
                    color="secondary"
                    fullWidth
                    label="Mine Duration"
                    placeholder="100.0"
                    error={Boolean(days.error)}
                    helperText={
                        days.error
                            ? days.error
                            : `Min deposit length ${staking.poolInfo?.minStakeLength} | Max deposit length ${staking.poolInfo?.maxStakeLength}`
                    }
                    value={days.value}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit();
                        }
                    }}
                    onChange={(e) => {
                        if (parseInt(e.target.value) > 5475) e.target.value = 5475;
                        setDays({ value: e.target.value, error: false });
                    }}
                    InputProps={{
                        inputComponent: NumberFormatField,
                    }}
                />
            </Box>
            <Box className={classes.slider}>
                <Slider
                    // valueLabelDisplay="auto"
                    onChange={(e, newValue) => setDays({ value: newValue, error: false })}
                    value={parseInt(days.value || 0)}
                    step={1}
                    min={15}
                    max={5475}
                />
            </Box>
            <Box className={classes.actions}>
                <LoadingButton fullWidth onClick={handleSubmit} loading={deposting} sx={{ marginTop: 2 }} variant="contained">
                    {needsApproval ? 'Approve' : 'Deposit'}
                </LoadingButton>
            </Box>
        </Card>
    );
}
