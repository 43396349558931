import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        padding: '24px 24px',
    },

    input: {
        textAlign: 'center',
    },
    field: {
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },

    understood: {
        padding: '12px 0px',
        borderTop: '1px solid #505050',
        borderBottom: '1px solid #505050',
    },

    stakes: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: 'auto',

        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    newStake: {
        textAlign: 'center',
    },

    actions: {
        display: 'flex',
        gap: 20,
    },
}));

export default useStyles;
