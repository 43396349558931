import { makeStyles } from '@mui/styles';
import theme from 'theme';

export const drawerWidth = 200;
export const drawerPlusPadding = drawerWidth + 16 * 2;

const useStyles = makeStyles(() => ({
    toolbar: {
        background: theme.gradients.navigation,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: 'white',
        zIndex: 2,
        minHeight: 72,
    },

    logo: {
        width: 45,
    },

    connectBox: {
        width: '100%',
        padding: 4,
        backgroundColor: 'rgba(255, 255, 255, .15)',
        borderRadius: 12,
        backdropFilter: 'blur(5px)',
        boxSizing: 'border-box',

        '& > button': {
            borderRadius: 10,
        },
    },

    addressBox: {
        padding: '4px 4px 4px 8px',
        width: '100%',
        borderRadius: 14,
        backgroundColor: 'rgba(255, 255, 255, .15)',
        backdropFilter: 'blur(5px)',
        boxSizing: 'border-box',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    address: {
        borderRadius: 8,
        padding: 4,
        backgroundColor: 'rgba(255, 255, 255, .35)',
        backdropFilter: 'blur(5px)',
    },

    connectMobile: {
        width: '60%',
    },
}));

export default useStyles;
