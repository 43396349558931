import * as React from 'react';
import { Link } from 'react-router-dom';
import Card from 'components/Cards/Card';
import { Box, Button, Typography } from '@mui/material';

import useStyles from './styles';

export default function MiningCard() {
    const classes = useStyles();

    return (
        <Card color="darkBlack" border="pelorusBlue" className={classes.root}>
            <Box className={classes.sectionTitleBox} sx={{ marginBottom: 2 }}>
                <Typography variant="h4" className={classes.cardText} sx={{ fontWeight: '600' }}>
                    Next Gen Mining
                </Typography>
            </Box>
            <Box className={classes.sectionBox} sx={{ marginBottom: 2 }}>
                <Typography variant="font1">
                    Generate $NXN at a high APY % with no hardware or electricity costs. Start earning today!
                </Typography>
            </Box>
            <Button component={Link} to="/mine" variant="contained" sx={{ marginTop: 3 }}>
                Start eMining!
            </Button>
        </Card>
    );
}
