import React, { useEffect, useState } from 'react';
// Modules
// UI
import { Alert, Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
// Styles
import useStyles from './styles';
import { ContractContext } from 'web3/WagmiListener';
import { useDispatch, useSelector } from 'react-redux';
import { burnTreasuryTokens, get as getBonds } from 'redux/actions/bonds';

import AvailableBonds from './AvailableBonds/AvailableBonds';
import BondInput from './BondInput/BondInput';
import MyBonds from './MyBonds/MyBonds';
import WalletCard from 'components/Cards/WalletCard/WalletCard';
import isTestnet from 'utils/is-testnet';
import TimingCard from 'components/Cards/TimingCard/TimingCard';
import { LoadingButton } from '@mui/lab';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import BurnButton from './BurnButton/BurnButton';

export default function Bonds() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { bonds, balances } = useSelector(({ bonds, balances }) => ({ bonds, balances }));
    const [selectedBond, setSelectedBond] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [burning, setBurning] = useState(false);
    const { initialized } = React.useContext(ContractContext);

    useEffect(() => {
        async function _getBonds() {
            !bonds.loaded && setLoading(true);
            try {
                dispatch(getBonds());
            } catch (error) {}
            !bonds.loaded && setLoading(false);
        }

        if (initialized) _getBonds();
    }, [initialized]);

    async function handleBurnTokens() {
        setBurning(true);
        try {
            await dispatch(burnTreasuryTokens());
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setBurning(false);
    }

    if (loading) {
        return (
            <Box className={classes.root}>
                <Container maxWidth="md" className={classes.fscenter}>
                    <CircularProgress size={45} />
                </Container>
            </Box>
        );
    }

    return (
        <>
            {!bonds.live && (
                <Alert severity="error" icon={false}>
                    <Typography variant="h6">AA is not currently live, please check back later.</Typography>
                </Alert>
            )}
            <Alert severity="info">
                <Typography variant="font2">Receive 5% back on energy tokens!</Typography>
            </Alert>
            <Box className={classes.root}>
                <BurnButton
                    actionButton={
                        <LoadingButton color="secondary" loading={burning} onClick={handleBurnTokens}>
                            Burn Tokens!
                        </LoadingButton>
                    }
                    secondsInDay={bonds?.secondsInDay}
                    title={`Next Burn (${bonds?.burns?.[bonds?.currentBurnIdx] || '10%'}%)`}
                    endTime={bonds?.nextBurn}
                />
                <Container maxWidth="md">
                    <Box className={classes.stat}>
                        <Box className={classes.tStat}>
                            <Typography variant="font2" fontWeight={500}>
                                Tokens Available {isTestnet() && '(Funded for testnet)'}
                            </Typography>
                            <Typography variant="h6" fontWeight={600}>
                                {bonds.availableTokens?.numberWithCommas?.(2)} NXN
                            </Typography>
                        </Box>
                        <Box className={classes.tStat}>
                            <Typography variant="font2" fontWeight={500}>
                                NXN Price
                            </Typography>
                            <Typography variant="h6" fontWeight={600}>
                                ${bonds.price?.numberWithCommas?.(6)}
                            </Typography>
                        </Box>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} className={classes.gridItem}>
                            <WalletCard balance="dai" color="dai" />
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.gridItem}>
                            <WalletCard balance="usdc" color="usdc" />
                        </Grid>
                    </Grid>

                    {bonds?.userBonds?.length > 0 && <MyBonds />}
                    <AvailableBonds onBondSelected={(b) => setSelectedBond(b)} />
                    {selectedBond !== -1 && <BondInput selectedBond={bonds.items[selectedBond]} />}
                </Container>
            </Box>
        </>
    );
}
