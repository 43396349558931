import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: { overflow: 'hidden' },
    header: {
        padding: '24px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.palette.darkPurple.main,
    },
    fieldRow: {
        padding: '0px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 16,

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 16,
        },
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        gap: 3,
    },
    slider: {
        padding: '0px 20px',
    },
    actions: {
        padding: '0px 20px 20px 20px',
    },
    stats: {
        padding: '12px 0px',
        display: 'flex',
        gap: '12px',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'space-around',
    },
    energyInfo: {
        display: 'flex',
        padding: '0px 20px',
        '& > img': {
            height: 20,
            width: 20,
            margin: '0px 6px',
        },
    },
}));

export default useStyles;
