export function getShares(amount, days, bonusSharesPeriod, bonusSharesPerPeriod) {
    if (!amount) return 0;

    const periodsStaked = parseInt(days / bonusSharesPeriod);

    if (periodsStaked == 0) return amount;
    else {
        const bonus = amount * periodsStaked * (bonusSharesPerPeriod / 100);
        return amount + bonus;
    }
}
