import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        color: 'inherit',
        height: '100%',
        padding: theme.spacing(3),
        textDecoration: 'none',

        display: 'flex',
        alignItems: 'center',
    },
    content: {
        flex: 1,
    },
    dividerColor: {
        background: theme.palette.secondary.main,
    },
    icon: {
        fill: theme.palette.lucarioBlue.main,
        color: theme.palette.lucarioBlue.main,
    },
    flexGapBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    overviewBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    sectionGapBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '12px',

        '& > svg': {
            height: 25,
            width: 25,
            color: theme.palette.lucarioBlue.main,
            fill: theme.palette.lucarioBlue.main,
        },
    },
}));

export default useStyles;
