import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        padding: '24px 0px',
    },
    cards: {
        display: 'flex',
        gap: '12px',
        alignItems: 'center',
        '& > div': {
            flex: 1,
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            '& > div': {
                width: '100%',
            },
        },
    },

    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
    },
}));

export default useStyles;
