import React, { useRef, useState } from 'react';
// Modules
import { useDispatch, useSelector } from 'react-redux';
// UI
import {
    Box,
    Button,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Styles
import useStyles from './styles';
import dayjs from 'dayjs';
import { setSnackbar } from 'redux/actions/snackbar';
import { metamaskErrorWrap } from 'utils/metamask-error';
import { redeem } from 'redux/actions/bonds';
import { useConfirm } from 'material-ui-confirm';
import { withdraw, withdrawRewards } from 'redux/actions/staking';
import ManageStakeDialog from 'components/Dialogs/ManageStakeDialog/ManageStakeDialog';

export default function MyDeposits({ sx }) {
    const classes = useStyles();
    const { staking } = useSelector(({ staking }) => ({ staking }));
    const currentTime = new Date().getTime() / 1000;
    const dispatch = useDispatch();
    const [redeeming, setRedeeming] = useState({});
    const [tab, setTab] = useState(0);
    const _redeeming = useRef({});
    const confirm = useConfirm();
    const [manage, setManage] = useState(false);

    function handleEarlyWithdraw(stake) {
        confirm({
            description: (
                <Typography variant="font1">
                    You are attempting to redeem a miner early. You will be{' '}
                    <span className="bold">penalized {stake.penalty.numberWithCommas(2)} NXN.</span> Your redeemable amount is{' '}
                    <span className="bold">{(stake.rewards + stake.data.amount - stake.penalty).numberWithCommas(2)}</span>
                </Typography>
            ),
        })
            .then(async () => {
                _redeeming.current[stake.id] = true;
                setRedeeming(_redeeming.current);
                try {
                    await dispatch(withdraw(stake.id));
                    dispatch(setSnackbar({ message: 'Redeemed!', severity: 'success' }));
                } catch (error) {
                    console.log(error);
                    dispatch(setSnackbar({ message: metamaskErrorWrap(error), severity: 'error' }));
                }
                delete _redeeming.current[stake.id];
                setRedeeming(_redeeming.current);
            })
            .catch(() => {});
    }

    function handleRedeem(stake) {
        const vestedLength = parseInt((now - stake.data.start) / (staking?.poolInfo?.secondsBetweenRewards ?? 1));
        const vestedPercent = (vestedLength / stake.data.sLength) * 100;

        const shareRateDeductionPercent = vestedPercent / 100;
        const shareRateDeduction = stake.data.shares * shareRateDeductionPercent;
        const penalty = stake.rewards * (staking.poolInfo.earlyRewardPenalty / 100);
        const withdrawable = stake.rewards - penalty;

        confirm({
            description: (
                <Typography variant="font1">
                    You are attempting to withdraw rewards early. You will be{' '}
                    <span className="bold">penalized {shareRateDeduction.numberWithCommas(2)} of your shares.</span> Your redeemable amount
                    of rewards is{' '}
                    <span className="bold">
                        {withdrawable.numberWithCommas(2)} and {penalty.numberWithCommas(2)} will be distributed to miners
                    </span>
                </Typography>
            ),
        })
            .then(async () => {
                _redeeming.current[stake.id] = true;
                setRedeeming(_redeeming.current);
                try {
                    await dispatch(withdrawRewards(stake.id));
                    dispatch(setSnackbar({ message: 'Rewards withdrawn!', severity: 'success' }));
                } catch (error) {
                    console.log(error);
                    dispatch(setSnackbar({ message: metamaskErrorWrap(error), severity: 'error' }));
                }
                delete _redeeming.current[stake.id];
                setRedeeming(_redeeming.current);
            })
            .catch(() => {});
    }

    const now = new Date().getTime() / 1000;

    return (
        <Box className={classes.root} sx={sx}>
            <ManageStakeDialog open={Boolean(manage)} stake={manage} onClose={() => setManage(false)} />

            <Box sx={{ borderBottom: 1, borderColor: '#515151' }} className={classes.tabs}>
                <Tabs
                    textColor="secondary"
                    value={tab}
                    indicatorColor="secondary"
                    scrollButtons="auto"
                    variant="scrollable"
                    onChange={(_, v) => setTab(v)}
                >
                    <Tab label="Active Miners" />
                    <Tab label="Deactived Miners" />
                </Tabs>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="tableHeader1">Name</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1">Days Staked</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1">Amount</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1">Shares</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1">Current APR</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1">Rewards</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1">Percent Vested</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1">End Date</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1"></Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {staking.stakes?.map?.((item, i) => {
                            const vestedUntil = item?.data?.start + item?.data?.sLength * staking?.poolInfo?.secondsBetweenRewards;
                            const vestedLength = parseInt((now - item?.data?.start) / (staking?.poolInfo?.secondsBetweenRewards ?? 1));
                            const vestedPercent = (vestedLength / item?.data?.sLength) * 100;

                            const daysMined = (now - item.data.start) / staking?.poolInfo?.secondsBetweenRewards;
                            const liveApr = (item.rewards / item.data.amount / (daysMined / 365)) * 100;

                            if (tab === 0 && item?.data?.withdrawn === 1) return;
                            if (tab === 1 && item?.data?.withdrawn === 0) return;

                            return (
                                <TableRow key={`stake-${i}`}>
                                    <TableCell>
                                        <Typography variant="font1" fontWeight="500">
                                            {item?.data?.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="font1" fontWeight="500">
                                            {parseInt(daysMined)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="font1" fontWeight="500">
                                            {item?.data?.amount.numberWithCommas(0)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="font1" fontWeight="500">
                                            {item?.data?.shares.numberWithCommas(0)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="font1" fontWeight="500">
                                            {liveApr?.numberWithCommas?.(2)}%
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="font1" fontWeight="500">
                                            {item?.rewards?.numberWithCommas(2)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="font1" fontWeight="500">
                                            {vestedPercent.numberWithCommas(2)}%
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="font1" fontWeight="500">
                                            {dayjs(vestedUntil * 1000).format('MM/DD/YY')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip arrow title={`Vested Percent must be greater then 10% before redeeming rewards`}>
                                            <span>
                                                <LoadingButton
                                                    color="secondary"
                                                    loading={redeeming[item?.id]}
                                                    onClick={() => handleEarlyWithdraw(item)}
                                                    disabled={vestedPercent < 10.01}
                                                >
                                                    Early
                                                </LoadingButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip arrow title={`Must mine for ${staking.poolInfo.minStakeLength} days before redeeming`}>
                                            <span>
                                                <LoadingButton
                                                    color="secondary"
                                                    disabled={vestedLength < staking?.poolInfo?.minStakeLength}
                                                    loading={redeeming[item?.id]}
                                                    onClick={() => handleRedeem(item)}
                                                >
                                                    Redeem Rewards
                                                </LoadingButton>
                                            </span>
                                        </Tooltip>
                                        <Button color="secondary" onClick={() => setManage(item)}>
                                            Manage
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
