import * as React from 'react';
import { Box, Button, Drawer, IconButton, Tooltip, Typography } from '@mui/material';

import clsx from 'clsx';
import { items } from '../items';
import { Link, useLocation } from 'react-router-dom';
import { BiWalletAlt } from 'react-icons/bi';
import Logo from 'assets/images/logos/logo-outline-purple.png';
import useStyles from './styles';
import { useWeb3Modal } from '@web3modal/react';
import { useAccount, useBalance, useNetwork, useSwitchNetwork } from 'wagmi';
import { Twitter } from '@mui/icons-material';
import { FaDiscord } from 'react-icons/fa';
import { SiGitbook } from 'react-icons/si';
import { CHAIN, CHAIN_NAME, DISCORD_LINK, GITBOOK_LINK, TWITTER_LINK } from 'utils/constants';

import TokenAbi from 'web3/abi/tokens/token.json';
import isTestnet from 'utils/is-testnet';
import isAllowedChain from 'utils/is-allowed-chain';
// import TokenAbi from './abi/tokens/token.json';

export default function SideNav() {
    const classes = useStyles();
    const { open } = useWeb3Modal();
    const { isConnected, address } = useAccount();
    const { data } = useBalance({ address });
    const location = useLocation();
    const { chain } = useNetwork();
    const { switchNetwork } = useSwitchNetwork();

    async function addToken() {
        await ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                    address: TokenAbi.Address[chain.id], // The address that the token is at.
                    symbol: 'NXN', // A ticker symbol or shorthand, up to 5 chars.
                    decimals: '9', // The number of decimals in the token
                    image: 'https://nxn-public.s3.amazonaws.com/logo-outline-purple.png', // A string url of the token logo
                },
            },
        });
    }

    return (
        <Drawer anchor="left" variant="permanent" classes={{ paper: classes.sideNav }}>
            <Box>
                <Box
                    className={clsx('links', classes.logoBox)}
                    component={Link}
                    to={isTestnet() ? 'https://mine.nxn.network' : '/'}
                    sx={{ paddingY: 4 }}
                >
                    <img src={Logo} className={classes.logo} />
                    <Typography variant="h3" sx={{ fontWeight: '600' }}>
                        NXN
                    </Typography>
                </Box>

                {!isAllowedChain(chain?.id) && (
                    <Box className={classes.switchBox} onClick={() => switchNetwork && switchNetwork(CHAIN)} sx={{ marginBottom: 1 }}>
                        <Box>
                            <Typography variant="font3" sx={{ fontWeight: '500' }}>
                                Incorrect Network, Click to switch to {CHAIN_NAME}
                            </Typography>
                        </Box>
                    </Box>
                )}
                {!isConnected && (
                    <Box className={classes.connectBox} sx={{ marginBottom: 4 }}>
                        <Button onClick={open} variant="contained" fullWidth startIcon={<BiWalletAlt />}>
                            Connect wallet
                        </Button>
                    </Box>
                )}
                {isConnected && (
                    <Box className={classes.addressBox} onClick={open} sx={{ marginBottom: 4 }}>
                        <Typography variant="font3" sx={{ fontWeight: '500' }}>
                            {data?.formatted?.numberWithCommas(2)} {data?.symbol}
                        </Typography>
                        <Box className={classes.address}>
                            <Typography variant="font3" sx={{ fontWeight: '500' }}>
                                {address.shortenAddress()}
                            </Typography>
                        </Box>
                    </Box>
                )}

                <Box className={classes.navItems}>
                    {items.map(({ Icon, external, hidden, ...item }, i) => {
                        if (hidden) return null;
                        if (external) {
                            return (
                                <Box
                                    key={`navitem-${i}`}
                                    component={'a'}
                                    href={item.pathname}
                                    target="_blank"
                                    className={clsx(classes.navItem, 'links', {
                                        [classes.activeItem]: location.pathname === item.pathname,
                                    })}
                                >
                                    <Icon />
                                    <Typography variant="font1" sx={{ fontWeight: '500' }}>
                                        {item.text}
                                    </Typography>
                                </Box>
                            );
                        }

                        return (
                            <Box
                                key={`navitem-${i}`}
                                component={Link}
                                to={item.pathname}
                                className={clsx(classes.navItem, 'links', {
                                    [classes.activeItem]: location.pathname === item.pathname,
                                })}
                            >
                                <Icon />
                                <Typography variant="font1" sx={{ fontWeight: '500' }}>
                                    {item.text}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            </Box>

            <Box className={classes.actions}>
                <IconButton component="a" href={GITBOOK_LINK}>
                    <SiGitbook />
                </IconButton>
                <IconButton component="a" href={TWITTER_LINK}>
                    <Twitter />
                </IconButton>
                <IconButton component="a" href={DISCORD_LINK}>
                    <FaDiscord />
                </IconButton>
                {TokenAbi?.Address?.[chain?.id] && (
                    <Tooltip title="Add to wallet" arrow>
                        <IconButton onClick={addToken}>
                            <img src={Logo} className={classes.addToken} />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </Drawer>
    );
}
