import { makeStyles } from '@mui/styles';
import theme from 'theme';

export const drawerWidth = 240;

const useStyles = makeStyles(() => ({
    sideNav: {
        background: theme.gradients.navigation,
        width: drawerWidth,
        padding: '0px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },

    logoBox: {
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        '& > img': {
            marginRight: theme.spacing(2),
        },
    },
    logo: {
        width: 45,
    },
    addToken: {
        width: 23,
    },

    connectBox: {
        width: '100%',
        padding: 4,
        backgroundColor: 'rgba(255, 255, 255, .15)',
        borderRadius: 12,
        backdropFilter: 'blur(5px)',
        boxSizing: 'border-box',

        '& > button': {
            borderRadius: 10,
        },
    },

    addressBox: {
        padding: '4px 4px 4px 8px',
        width: '100%',
        borderRadius: 14,
        backgroundColor: 'rgba(255, 255, 255, .15)',
        backdropFilter: 'blur(5px)',
        boxSizing: 'border-box',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },

    address: {
        borderRadius: 8,
        padding: 4,
        backgroundColor: 'rgba(255, 255, 255, .35)',
        backdropFilter: 'blur(5px)',
    },

    switchBox: {
        cursor: 'pointer',
        padding: '8px 8px 8px 8px',
        width: '100%',
        borderRadius: 14,
        backgroundColor: 'rgba(255, 255, 255, .15)',
        backdropFilter: 'blur(5px)',
        boxSizing: 'border-box',

        '& > div': {
            borderRadius: 8,
            padding: 4,
            backgroundColor: theme.palette.error.main,
            backdropFilter: 'blur(5px)',
        },
    },

    connectMobile: {
        width: '60%',
    },

    navItems: {
        '& > a:not(:last-child)': {
            marginBottom: theme.spacing(2),
        },
    },
    navItem: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        borderRadius: 6,
        transition: '0.2s all ease-in',

        '& > svg': {
            marginRight: theme.spacing(1),
        },

        '&:hover': {
            background: theme.gradients.purpleToLightGradient,
        },
    },

    activeItem: {
        background: theme.gradients.purpleToLightGradient,
    },
    actions: {
        borderTop: `1px solid rgba(255,255,255,0.2)`,
        padding: '10px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
}));

export default useStyles;
