export const DialogInformation = {
    apr: {
        title: 'What is APR Slowdown',
        body: 'Some information here about apr slowdown',
    },
    halving: {
        title: 'What is Halving',
        body: 'Some information here about apr slowdown',
    },
    testnet: {
        title: 'Testnet',
        body: 'Welcome to testnet! Play around with our staking platform and if the next payout timer is 0, click update pool!',
    },
};
