import React, { createContext, useEffect, useRef, useState } from 'react';
import { useAccount, useNetwork, useProvider, useSigner } from 'wagmi';
import BlockChain from './Blockchain';
import { CHAIN, RPCS, DEAD_ADDRESS } from 'utils/constants';
import { ethers } from 'ethers';
import { useDispatch } from 'react-redux';
import { get as getBalances } from 'redux/actions/balances';

export const ContractContext = createContext();

const WagmiListener = (props) => {
    const { address, isConnected } = useAccount();
    const provider = useProvider();
    const { chain } = useNetwork();
    const initializing = useRef();
    const [initialized, setInitialized] = useState(null);
    const { data: signer } = useSigner();
    const dispatch = useDispatch();
    const timers = useRef({});

    useEffect(() => {
        if (isConnected && chain && signer) {
            initialize();
        } else if (!isConnected) {
            cheapSetup();
        }

        return () => {
            Object.keys(timers.current).forEach((key) => {
                clearInterval(timers.current[key]);
            });
        };
    }, [isConnected, chain, address, signer]);

    function cheapSetup() {
        const provider = new ethers.providers.JsonRpcProvider(RPCS[CHAIN]);
        BlockChain.initialize({ address: DEAD_ADDRESS, chain: CHAIN, provider, signer });
        setInitialized(`cheap-0x0`);
    }

    function initialize() {
        const initPhrase = `${chain.id}-${address}`;
        /** isConnected and chain will fire multiple times */
        if (initializing.current) return;
        if (initialized === initPhrase) return;

        initializing.current = true;
        BlockChain.initialize({ address, provider, chain: chain.id, signer });
        setBalanceTimer();
        setInitialized(initPhrase); // this account for chain change and address change
        initializing.current = false;
    }

    function setBalanceTimer() {
        if (timers.current.balance) clearInterval(timers.current.balance);

        dispatch(getBalances());
        timers.current.balance = setInterval(() => {
            dispatch(getBalances());
        }, 60 * 1000);
    }

    const values = { initialized, isConnected };
    return <ContractContext.Provider value={values}>{props.children}</ContractContext.Provider>;
};

export default WagmiListener;
