import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        padding: theme.spacing(4),
    },
    stats: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },

    tStat: {
        textAlign: 'center',
    },

    actions: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        margin: 'auto',
        marginTop: 32,

        [theme.breakpoints.down('md')]: {
            width: '80%',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },

    principles: {
        display: 'flex',
        alignItems: 'center',
        '& > img:first-child': {
            height: 30,
            width: 30,
            objectFit: 'contain',
            position: 'relative',
            zIndex: 2,
        },
        '& > img:nth-child(2)': {
            position: 'relative',
            zIndex: 1,
            height: 35,
            width: 35,
            objectFit: 'contain',
            marginLeft: -20,
        },
    },
}));

export default useStyles;
