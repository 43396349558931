import React, { useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    FormControlLabel,
    InputAdornment,
    Slider,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import useStyles from './styles';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import { metamaskErrorWrap } from 'utils/metamask-error';
import { split } from 'redux/actions/staking';

export default function SplitStake({ onClose, stake }) {
    const classes = useStyles();
    const theme = useTheme();
    const [percent, setPercent] = useState(50);
    const { staking } = useSelector(({ staking }) => ({ staking }));
    const [splitting, setSplitting] = useState(false);
    const [understood, setUnderstood] = useState(false);
    const dispatch = useDispatch();

    async function handleSubmit() {
        try {
            setSplitting(true);
            try {
                await dispatch(split(stake.id, percent));
                dispatch(setSnackbar({ message: 'Miner split!', severity: 'success' }));
                onClose?.();
            } catch (error) {
                console.log(error);
                dispatch(setSnackbar({ message: metamaskErrorWrap(error), severity: 'error' }));
            }
            setSplitting(false);
        } catch (error) {}
    }

    const splitAmount = ((stake?.data?.amount ?? 0) * percent) / 100;
    const splitShares = ((stake?.data?.shares ?? 0) * percent) / 100;

    return (
        <Box className={classes.root}>
            <Box className={classes.input} sx={{ marginBottom: 3 }}>
                <Typography mb={2}>
                    Split your eMiner into two. Your principal and shares will be split based on the percentage you choose. The length of
                    the eMiner will remain the same.
                </Typography>
                <Typography variant="h6">{percent}%</Typography>
                <Typography>Split Percentage</Typography>
                <Slider
                    // valueLabelDisplay="auto"
                    color="secondary"
                    className={classes.field}
                    onChange={(e, newValue) => setPercent(newValue)}
                    value={percent}
                    step={1}
                    min={10}
                    max={90}
                />
                <Box className={classes.stakes}>
                    <Box className={classes.newStake}>
                        <Typography>Old Miner</Typography>
                        <Typography>Principle: {((stake?.data?.amount ?? 0) - splitAmount).numberWithCommas(2)}~</Typography>
                        <Typography>Shares: {((stake?.data?.shares ?? 0) - splitShares).numberWithCommas(2)}~</Typography>
                    </Box>
                    <Box className={classes.newStake}>
                        <Typography>New Miner</Typography>
                        <Typography>Principle: {splitAmount.numberWithCommas(2)}~</Typography>
                        <Typography>Shares: {splitShares.numberWithCommas(2)}~</Typography>
                    </Box>
                </Box>
            </Box>

            <Box className={classes.understood} sx={{ marginBottom: 2 }}>
                <FormControlLabel
                    control={<Checkbox value={understood} onChange={(e) => setUnderstood(e.target.checked)} />}
                    label={
                        <span>
                            I understand that{' '}
                            <span style={{ color: theme.palette.energy.main }}>{staking?.poolInfo?.energyPerSplit} energy token(s)</span>{' '}
                            will be taken from my wallet
                        </span>
                    }
                />
            </Box>

            <Box className={classes.actions}>
                <Button variant="outlined" fullWidth color="secondary" onClick={onClose} autoFocus>
                    Cancel
                </Button>
                <LoadingButton
                    loading={splitting}
                    variant="contained"
                    color="secondary"
                    disabled={!understood}
                    fullWidth
                    onClick={handleSubmit}
                    autoFocus
                >
                    Submit
                </LoadingButton>
            </Box>
        </Box>
    );
}
