export const SET_BALANCES = 'SET_BALANCES';
export const BALANCES_ERROR = 'BALANCES_ERROR';
export const INIT = {};

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_BALANCES:
            return payload;
        case BALANCES_ERROR:
        default:
            return state;
    }
}

export default reducer;
