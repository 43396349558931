import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        padding: '24px 0px',
    },

    bond: {
        padding: theme.spacing(4),
    },
    bondTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },

    stat: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: theme.spacing(4),
    },
    tStat: {
        textAlign: 'center',
    },

    principles: {
        display: 'flex',
        alignItems: 'center',
        '& > img:first-child': {
            height: 40,
            width: 40,
            objectFit: 'contain',
            position: 'relative',
            zIndex: 2,
        },
        '& > img:nth-child(2)': {
            position: 'relative',
            zIndex: 1,
            height: 35,
            width: 35,
            objectFit: 'contain',
            marginLeft: -20,
        },
    },

    row: {
        cursor: 'pointer',
    },

    activeRow: {
        backgroundColor: 'rgba(255,255,255,0.1)',
    },
}));

export default useStyles;
