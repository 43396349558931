import * as React from 'react';
import useStyles from './styles';
import { Box, Tab, Tabs, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import TabPanel from 'components/Widgets/TabPanel/TabPanel';
import Rename from './Rename/Rename';
import SplitStake from './SplitStake/SplitStake';
import Transfer from './Transfer/Transfer';

export default function ManageStakeDialog({ open, stake, onClose }) {
    const classes = useStyles();
    const [tab, setTab] = React.useState(0);

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.paper }}
            maxWidth="md"
            fullWidth
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Manage eMiner: {stake?.data?.name}</DialogTitle>
            <Box className={classes.content}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={classes.tabs}>
                    <Tabs
                        textColor="secondary"
                        indicatorColor="secondary"
                        scrollButtons="auto"
                        variant="scrollable"
                        value={tab}
                        onChange={(_, v) => setTab(v)}
                    >
                        <Tab label="Rename eMiner" />
                        <Tab label="Split eMiner" />
                        <Tab label="Transfer eMiner" />
                    </Tabs>
                </Box>
                <TabPanel value={tab} index={0}>
                    <Rename onClose={onClose} stake={stake} />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <SplitStake onClose={onClose} stake={stake} />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                    <Transfer onClose={onClose} stake={stake} />
                </TabPanel>
            </Box>
        </Dialog>
    );
}
