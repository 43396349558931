import { ethers } from 'ethers';
// Staking
import StakingAbi from './abi/staking/staking.json';
import StakeTokenAbi from './abi/staking/stakeToken.json';
import UserSharesAbi from './abi/staking/userShares.json';
// Tokens
import TokenAbi from './abi/tokens/token.json';
import TokensAbi from './abi/tokens/tokens.json';
// Uni
import PairAbi from './abi/uniswap/pair.json';
// Bonds
import BondAbi from './abi/bonds/bond.json';
import BondTokenAbi from './abi/bonds/bondToken.json';
import CalculatorAbi from './abi/bonds/calculator.json';
import TreasuryAbi from './abi/bonds/treasury.json';
import TreasuryV2Abi from './abi/bonds/treasuryv2.json';

// Utilies
import TransferTokenAbi from './abi/utilities/transferToken.json';
import EnergyTokenAbi from './abi/utilities/energyToken.json';
import ReaderAbi from './abi/reader.json';

// process.env.REACT_APP_VERCEL_ENV

class BlockChain {
    static initialize({ address, chain, provider, signer }) {
        BlockChain.provider = provider;
        BlockChain.address = address;
        BlockChain.signer = signer;
        BlockChain.chain = chain;

        BlockChain.contracts = BlockChain.loadContracts(chain, provider);
    }

    static getInfo() {
        return { contracts: BlockChain.contracts, chain: BlockChain.chain, address: BlockChain.address, signer: BlockChain.signer };
    }

    static loadContracts(chain, provider) {
        const contracts = {};
        // Staking
        if (StakingAbi.Address[chain]) contracts.staking = new ethers.Contract(StakingAbi.Address[chain], StakingAbi.ABI, provider);
        if (StakeTokenAbi.Address[chain])
            contracts.stakeToken = new ethers.Contract(StakeTokenAbi.Address[chain], StakeTokenAbi.ABI, provider);
        if (UserSharesAbi.Address[chain])
            contracts.userShares = new ethers.Contract(UserSharesAbi.Address[chain], UserSharesAbi.ABI, provider);
        // Tokens
        if (TokenAbi.Address[chain]) contracts.token = new ethers.Contract(TokenAbi.Address[chain], TokenAbi.ABI, provider);
        if (TokensAbi.DAI[chain]) contracts.dai = new ethers.Contract(TokensAbi.DAI[chain], TokensAbi.ABI, provider);
        if (TokensAbi.USDC[chain]) contracts.usdc = new ethers.Contract(TokensAbi.USDC[chain], TokensAbi.ABI, provider);
        if (TokensAbi.USDT[chain]) contracts.usdt = new ethers.Contract(TokensAbi.USDT[chain], TokensAbi.ABI, provider);
        // Uniswap
        if (PairAbi.Address[chain]) contracts.pair = new ethers.Contract(PairAbi.Address[chain], PairAbi.ABI, provider);
        // bonds
        if (BondAbi.USDC[chain]) contracts.usdcBond = new ethers.Contract(BondAbi.USDC[chain], BondAbi.ABI, provider);
        if (BondAbi.DAI[chain]) contracts.daiBond = new ethers.Contract(BondAbi.DAI[chain], BondAbi.ABI, provider);
        if (BondTokenAbi.Address[chain]) contracts.bondToken = new ethers.Contract(BondTokenAbi.Address[chain], BondTokenAbi.ABI, provider);
        if (CalculatorAbi.Address[chain])
            contracts.calculator = new ethers.Contract(CalculatorAbi.Address[chain], CalculatorAbi.ABI, provider);
        if (TreasuryAbi.Address[chain]) contracts.treasury = new ethers.Contract(TreasuryAbi.Address[chain], TreasuryAbi.ABI, provider);
        if (TreasuryV2Abi.Address[chain])
            contracts.treasuryV2 = new ethers.Contract(TreasuryV2Abi.Address[chain], TreasuryV2Abi.ABI, provider);

        // Utilties
        if (EnergyTokenAbi.Address[chain])
            contracts.energyToken = new ethers.Contract(EnergyTokenAbi.Address[chain], EnergyTokenAbi.ABI, provider);
        if (TransferTokenAbi.Address[chain])
            contracts.transferToken = new ethers.Contract(TransferTokenAbi.Address[chain], TransferTokenAbi.ABI, provider);
        // Reader
        if (ReaderAbi.Address[chain]) contracts.reader = new ethers.Contract(ReaderAbi.Address[chain], ReaderAbi.ABI, provider);

        return contracts;
    }
}

export default BlockChain;
