import BlockChain from 'web3/Blockchain';
import cloneDeep from 'lodash/cloneDeep';
import { BONDS_ERROR, SET_BONDS } from '../reducers/bonds';
import { bondInfoFormatter, userBondFormatter, treasuryV2VariablesFormatter } from 'utils/bc-formatter';
import { DECIMALS, CHAIN_NAME, CHAIN } from 'utils/constants';
import { ethers } from 'ethers';

export const get = () => async (dispatch, getState) => {
    const { address, contracts } = BlockChain.getInfo();

    try {
        const [usdcBond, daiBond, userBonds, availableTokens] = await Promise.all([
            contracts?.usdcBond?.getInfo?.() ?? {},
            contracts?.daiBond?.getInfo?.() ?? {},
            contracts?.reader?.getBonds?.(address) ?? [],
            contracts?.token?.balanceOf?.(contracts?.treasuryV2?.address ?? ethers.constants.AddressZero) ?? 0,
        ]);

        const [approvalUSDC, approvalDAI] = await Promise.all([
            contracts?.usdc?.allowance?.(address, contracts?.usdcBond?.address ?? ethers.constants.AddressZero) ?? 0,
            contracts?.dai?.allowance?.(address, contracts?.daiBond?.address ?? ethers.constants.AddressZero) ?? 0,
        ]);

        const price = (await contracts?.calculator?.getUniswapTokenPrice?.()) ?? null;
        const treasuryValues = await contracts?.treasuryV2?.getVariables?.();

        return dispatch({
            payload: {
                loaded: true,
                live: Boolean(contracts?.usdcBond) && Boolean(contracts?.daiBond),
                availableTokens: availableTokens?.formatUnits?.() ?? 0,
                price: price?.formatUnits?.() ?? 0,
                ...treasuryV2VariablesFormatter(treasuryValues),
                items: [
                    {
                        approval: approvalUSDC?.formatUnits?.(DECIMALS.usdc) ?? 0,
                        contract: 'usdcBond',
                        principle: 'usdc',
                        principles: ['usdc'],
                        info: bondInfoFormatter(usdcBond),
                    },
                    {
                        approval: approvalDAI?.formatUnits?.(DECIMALS.dai) ?? 0,
                        contract: 'daiBond',
                        principle: 'dai',
                        principles: ['dai'],
                        info: bondInfoFormatter(daiBond),
                    },
                ],
                userBonds: userBonds.map((bond) => userBondFormatter(bond)),
            },
            type: SET_BONDS,
        });
    } catch (error) {
        console.log(error);
        dispatch({
            type: BONDS_ERROR,
        });
    }
};

export const approve = (principle, contract) => async (dispatch, getState) => {
    const { signer, contracts, chain } = BlockChain.getInfo();

    const bonds = cloneDeep(getState().bonds);
    const units = DECIMALS[principle];

    try {
        if (!signer) throw new Error('Please connect your wallet');
        if (chain !== CHAIN) throw new Error(`Connected to an unsupported chain, please connect to ${CHAIN_NAME}`);

        const tx = await contracts[principle].connect(signer).approve(contracts[contract].address, ethers.constants.MaxUint256);
        const finished = await tx.wait(); // tx.hash
        const event = finished.events.find((e) => e.event === 'Approval');

        if (event) {
            bonds.items.forEach((item) => {
                if (item.principle === principle) {
                    item.approval = event.args.value.formatUnits(units);
                }
            });
        }

        return dispatch({
            payload: bonds,
            type: SET_BONDS,
        });
    } catch (error) {
        dispatch({
            type: BONDS_ERROR,
        });

        throw error;
    }
};

export const deposit = (amount, principle, contract) => async (dispatch, getState) => {
    const { signer, address, contracts, chain } = BlockChain.getInfo();

    const units = DECIMALS[principle];
    const bonds = cloneDeep(getState().bonds);

    try {
        if (!signer) throw new Error('Please connect your wallet');
        if (chain !== CHAIN) throw new Error(`Connected to an unsupported chain, please connect to ${CHAIN_NAME}`);

        console.log(ethers.utils.parseUnits(amount, units).toString());
        const tx = await contracts[contract].connect(signer).deposit(ethers.utils.parseUnits(amount, units), address);
        await tx.wait(); // tx.hash

        const userBonds = await contracts.reader.getBonds(address);
        bonds.userBonds = userBonds.map((bond) => userBondFormatter(bond));

        return dispatch({
            payload: bonds,
            type: SET_BONDS,
        });
    } catch (error) {
        dispatch({
            type: BONDS_ERROR,
        });

        throw error;
    }
};

export const redeem = (id, cAddress) => async (dispatch, getState) => {
    const { signer, address, contracts, chain } = BlockChain.getInfo();
    const bonds = cloneDeep(getState().bonds);

    try {
        if (!signer) throw new Error('Please connect your wallet');
        if (chain !== CHAIN) throw new Error(`Connected to an unsupported chain, please connect to ${CHAIN_NAME}`);

        const cKeys = Object.keys(contracts);
        let contract;
        for (let i = 0; i < cKeys.length; i++) {
            const key = cKeys[i];
            if (contracts[key].address === cAddress) {
                contract = key;
            }
        }
        if (!contract) throw new Error('Contract not found');

        const tx = await contracts[contract].connect(signer).redeem(id);
        await tx.wait(); // tx.hash

        const userBonds = await contracts.reader.getBonds(address);
        bonds.userBonds = userBonds.map((bond) => userBondFormatter(bond));

        return dispatch({
            payload: bonds,
            type: SET_BONDS,
        });
    } catch (error) {
        dispatch({
            type: BONDS_ERROR,
        });

        throw error;
    }
};

export const burnTreasuryTokens = () => async (dispatch, getState) => {
    const { signer, contracts, chain } = BlockChain.getInfo();

    try {
        if (!signer) throw new Error('Please connect your wallet');
        if (chain !== CHAIN) throw new Error(`Connected to an unsupported chain, please connect to ${CHAIN_NAME}`);

        const tx = await contracts.treasuryV2.connect(signer).burnTokens();
        await tx.wait(); // tx.hash

        await dispatch(get());
    } catch (error) {
        dispatch({
            type: BONDS_ERROR,
        });

        throw error;
    }
};

export const set = (payload) => (dispatch, getState) => {
    return dispatch({
        payload,
        type: SET_BONDS,
    });
};
