import BlockChain from 'web3/Blockchain';
import { BALANCES_ERROR, SET_BALANCES } from '../reducers/balances';
import { cloneDeep } from 'lodash';

export const get = () => async (dispatch, getState) => {
    const { address, contracts, chain } = BlockChain.getInfo();

    try {
        const [dai, usdc, usdt, nxn, energy, dec_dai, dec_usdc, dec_usdt] = await Promise.all([
            contracts?.dai?.balanceOf?.(address) ?? 0,
            contracts?.usdc?.balanceOf?.(address) ?? 0,
            contracts?.usdt?.balanceOf?.(address) ?? 0,
            contracts?.token?.balanceOf?.(address) ?? 0,
            contracts?.energyToken?.getUserEnergy?.(address) ?? 0,
            contracts?.dai?.decimals?.() ?? 18,
            contracts?.usdc?.decimals?.() ?? 18,
            contracts?.usdt?.decimals?.() ?? 18,
        ]);
        const nxnPrice = (await contracts?.calculator?.getUniswapTokenPrice?.()) ?? 0;

        return dispatch({
            payload: {
                decimals: {
                    dai: dec_dai.toString(),
                    usdt: dec_usdt.toString(),
                    usdc: dec_usdc.toString(),
                },
                dai: dai?.formatUnits?.(dec_dai.toString()) ?? 0,
                usdc: usdc?.formatUnits?.(dec_usdc.toString()) ?? 0,
                usdt: usdt?.formatUnits?.(dec_usdt.toString()) ?? 0,
                nxn: nxn?.formatUnits?.() ?? 0,
                nxnPrice: nxnPrice?.formatUnits?.() ?? 0,
                energy: energy?.toNumber?.() ?? 0,
            },
            type: SET_BALANCES,
        });
    } catch (error) {
        console.log(error);
        dispatch({
            type: BALANCES_ERROR,
        });
    }
};

export const addBalance = (asset, amount) => (dispatch, getState) => {
    const balances = cloneDeep(getState().balances);

    balances[asset] += amount;

    return dispatch({
        payload: balances,
        type: SET_BALANCES,
    });
};

export const getEnergy = () => async (dispatch, getState) => {
    const { address, contracts } = BlockChain.getInfo();

    const balances = cloneDeep(getState().balances);

    const energy = (await contracts?.energyToken?.getUserEnergy?.(address)) ?? 0;

    balances.energy = energy?.toNumber?.() ?? 0;

    return dispatch({
        payload: balances,
        type: SET_BALANCES,
    });
};

export const set = (payload) => (dispatch, getState) => {
    return dispatch({
        payload,
        type: SET_BALANCES,
    });
};
