import React, { useState } from 'react';
import { Box, Button, Checkbox, DialogActions, FormControlLabel, InputAdornment, TextField, Typography } from '@mui/material';
import useStyles from './styles';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import { metamaskErrorWrap } from 'utils/metamask-error';
import { transfer } from 'redux/actions/staking';
import { ethers } from 'ethers';

export default function Transfer({ onClose, stake }) {
    const classes = useStyles();
    const [address, setAddress] = useState('');
    const [understood, setUnderstood] = useState(false);
    const { staking } = useSelector(({ staking }) => ({ staking }));
    const [transfering, setTransfering] = useState(false);
    const dispatch = useDispatch();

    async function handleSubmit() {
        try {
            setTransfering(true);
            try {
                if (!ethers.utils.isAddress(address)) {
                    throw new Error('Invalid address');
                }

                const _address = ethers.utils.getAddress(address);
                console.log(_address);

                await dispatch(transfer(stake.id, _address));
                dispatch(setSnackbar({ message: 'Miner transfered!', severity: 'success' }));
                onClose?.();
            } catch (error) {
                console.log(error);
                dispatch(setSnackbar({ message: metamaskErrorWrap(error), severity: 'error' }));
            }
            setTransfering(false);
        } catch (error) {}
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.input} sx={{ marginBottom: 3 }}>
                <Typography mb={2}>
                    some text here about transfering, some text here about transfering, some text here about transfering, some text here
                    about transfering, some text here about transfering,
                </Typography>
                <TextField
                    className={classes.field}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    label="Transfer to Address"
                />
            </Box>

            <Box className={classes.understood} sx={{ marginBottom: 2 }}>
                <FormControlLabel
                    control={<Checkbox value={understood} onChange={(e) => setUnderstood(e.target.checked)} />}
                    label={`I understand that ${staking?.poolInfo?.energyPerTransfer} energy tokens will be taken from my wallet`}
                />
            </Box>

            <Box className={classes.actions}>
                <Button variant="outlined" fullWidth color="secondary" onClick={onClose} autoFocus>
                    Cancel
                </Button>
                <LoadingButton
                    loading={transfering}
                    variant="contained"
                    color="secondary"
                    disabled={!understood}
                    fullWidth
                    onClick={handleSubmit}
                    autoFocus
                >
                    Submit
                </LoadingButton>
            </Box>
        </Box>
    );
}
