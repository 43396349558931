import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';

import useStyles from './styles';
import countdown from 'utils/countdown';
import { Info } from '@mui/icons-material';
import Card from '../Card/Card';

export default function TimingCard({
    title = 'Next Halving',
    secondsInDay = 86400,
    actionButton,
    onAction,
    endTime = 0,
    sx = {},
    ...props
}) {
    const classes = useStyles();
    const theme = useTheme();
    const [cd, setCd] = useState();
    const interval = useRef();

    useEffect(() => {
        if (interval.current) clearInterval(interval.current);

        interval.current = setInterval(() => {
            const v = countdown(endTime, secondsInDay);
            if (!v) {
                setCd(cd);
                return clearInterval(interval);
            }

            setCd(v);
        }, []);

        return () => clearInterval(interval.current);
    }, [endTime]);

    return (
        <Card className={classes.root} sx={sx} {...props}>
            <Box className={classes.header}>
                <Typography variant="h5" fontWeight={'700'}>
                    {title}
                </Typography>
                {actionButton && actionButton}
                {onAction && (
                    <IconButton onClick={onAction}>
                        <Info sx={{ fontSize: 18 }} />
                    </IconButton>
                )}
            </Box>
            <Box className={classes.content}>
                <Box className={classes.interval}>
                    <Typography variant="h4" fontWeight={'800'}>
                        {cd?.d ?? '00'}
                    </Typography>
                    <Typography variant="font2" fontWeight={'500'}>
                        Days
                    </Typography>
                </Box>
                <Typography variant="h4" fontWeight={'800'}>
                    :
                </Typography>
                <Box className={classes.interval}>
                    <Typography variant="h4" fontWeight={'800'}>
                        {cd?.h ?? '00'}
                    </Typography>
                    <Typography variant="font2" fontWeight={'500'}>
                        Hours
                    </Typography>
                </Box>
                <Typography variant="h4" fontWeight={'800'}>
                    :
                </Typography>
                <Box className={classes.interval}>
                    <Typography variant="h4" fontWeight={'800'}>
                        {cd?.m ?? '00'}
                    </Typography>
                    <Typography variant="font2" fontWeight={'500'}>
                        Minutes
                    </Typography>
                </Box>
                <Typography variant="h4" fontWeight={'800'}>
                    :
                </Typography>
                <Box className={classes.interval}>
                    <Typography variant="h4" fontWeight={'800'}>
                        {cd?.s ?? '00'}
                    </Typography>
                    <Typography variant="font2" fontWeight={'500'}>
                        Seconds
                    </Typography>
                </Box>
            </Box>
        </Card>
    );
}
