import { makeStyles } from '@mui/styles';
import theme from 'theme';
import { drawerWidth } from './SideNav/styles';

const useStyles = makeStyles(() => ({
    root: {
        minHeight: '100%',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    main: {
        flexGrow: 1,
        marginLeft: drawerWidth,
        // overflowY: 'auto',
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            padding: 0,
            paddingBottom: theme.spacing(2),
            // marginTop: NavigationBarHeight,
        },
    },
}));

export default useStyles;
