import React, { useState } from 'react';
import { useWeb3Modal } from '@web3modal/react';
import { useAccount } from 'wagmi';
import { useDispatch, useSelector } from 'react-redux';

import BlockChain from 'web3/Blockchain';
import { setSnackbar } from 'redux/actions/snackbar';
import { metamaskErrorWrap } from 'utils/metamask-error';
import { get as getBalances } from 'redux/actions/balances';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, FormControlLabel, Typography, setRef, useTheme } from '@mui/material';

import useStyles from './styles';
import { TOKEN_BACKGROUNDS, TOKEN_LOGOS } from 'utils/constants';
import isTestnet from 'utils/is-testnet';

export default function WalletCard({ color = 'usdc', balance = 'usdc', usdPer = 1, sx = {} }) {
    const classes = useStyles();
    const theme = useTheme();
    const { balances } = useSelector(({ balances }) => ({ balances }));
    const [hide, setHide] = useState(false);
    const { open } = useWeb3Modal();
    const { isConnected } = useAccount();
    const [refilling, setRefilling] = useState(false);
    const dispatch = useDispatch();

    async function handleRefill() {
        const { signer, address, contracts } = BlockChain.getInfo();
        setRefilling(true);
        try {
            const tx = await contracts[balance].connect(signer).freeMint(address);
            await tx.wait(); // tx.hash
            await dispatch(getBalances());
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: metamaskErrorWrap(error) }));
        }
        setRefilling(false);
    }

    return (
        <Box
            className={classes.root}
            sx={{ ...sx, borderColor: theme.palette[color].main, background: `url(${TOKEN_BACKGROUNDS[balance]})` }}
        >
            <Box className={classes.row}>
                <img src={TOKEN_LOGOS[balance]} className={classes.walletImage} />
                <Typography variant="h6" fontWeight="600" sx={{ flex: 1, color: theme.palette[color].main }}>
                    {balance.toUpperCase()} Wallet
                </Typography>
                {!isConnected && (
                    <Button color={color} onClick={open}>
                        Connect Wallet
                    </Button>
                )}
                {isConnected && isTestnet() && ['usdc', 'usdt', 'dai'].includes(balance) && (
                    <LoadingButton loading={refilling} color={'white'} onClick={handleRefill}>
                        Refill
                    </LoadingButton>
                )}
            </Box>
            <Typography variant="font4" marginY={1}>
                {balance.toUpperCase()} Token
            </Typography>
            <Box className={classes.row}>
                <Typography
                    variant="h6"
                    fontWeight={'600'}
                    lineHeight={1}
                    sx={{
                        filter: hide ? 'blur(5px)' : 'unset',
                    }}
                >
                    {balances?.[balance]?.numberWithCommas(2) ?? 0}
                </Typography>
                <Typography variant="font4">{balance.toUpperCase()}</Typography>
            </Box>
            <Box className={classes.between}>
                <Box className={classes.row}>
                    <Typography
                        variant="font1"
                        fontWeight={'500'}
                        sx={{
                            filter: hide ? 'blur(4px)' : 'unset',
                        }}
                    >
                        {!usdPer ? 'N/A' : ((balances?.[balance] ?? 0) * usdPer).numberWithCommas(2)}
                    </Typography>
                    <Typography variant="font4">USD Value</Typography>
                </Box>
                <FormControlLabel
                    control={<Checkbox checked={hide} color={color} size="small" onChange={(e) => setHide(e.target.checked)} name="hide" />}
                    label={<Typography variant="font4">Hide Balance</Typography>}
                />
            </Box>
        </Box>
    );
}
