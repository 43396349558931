import * as React from 'react';
import { Box, Button, IconButton, Toolbar, Typography } from '@mui/material';

import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { BiWalletAlt } from 'react-icons/bi';
import Logo from 'assets/images/logos/logo-outline-purple.png';
import useStyles from './styles';
import { useWeb3Modal } from '@web3modal/react';
import { useAccount, useBalance } from 'wagmi';
import MiniDrawer from '../Drawer/Drawer';
import { Menu } from '@mui/icons-material';

export default function TopNav({ children }) {
    const classes = useStyles();
    const { open } = useWeb3Modal();
    const { isConnected, address } = useAccount();
    const { data } = useBalance({ address });
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    return (
        <>
            <Toolbar className={classes.toolbar}>
                <Link to="/">
                    <img src={Logo} className={classes.logo} />
                </Link>

                {!isConnected && (
                    <Box className={clsx(classes.connectBox, classes.connectMobile)}>
                        <Button onClick={open} variant="contained" fullWidth startIcon={<BiWalletAlt />}>
                            Connect wallet
                        </Button>
                    </Box>
                )}
                {isConnected && (
                    <Box className={clsx(classes.addressBox, classes.connectMobile)} onClick={open}>
                        <Typography variant="font3" sx={{ fontWeight: '500' }}>
                            {data?.formatted?.numberWithCommas(2)} {data?.symbol}
                        </Typography>
                        <Box className={classes.address}>
                            <Typography variant="font3" sx={{ fontWeight: '500' }}>
                                {address.shortenAddress()}
                            </Typography>
                        </Box>
                    </Box>
                )}

                <IconButton onClick={() => setDrawerOpen(true)}>
                    <Menu />
                </IconButton>
            </Toolbar>
            <MiniDrawer isOpen={drawerOpen} onClose={() => setDrawerOpen(false)} />
        </>
    );
}
