import React, { useRef, useState } from 'react';
// Modules
// UI
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
// Styles
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/Cards/Card/Card';
import { LoadingButton } from '@mui/lab';
import { withdrawDivToken } from 'redux/actions/staking';
import { setSnackbar } from 'redux/actions/snackbar';
import { metamaskErrorWrap } from 'utils/metamask-error';

export default function DivTokens({ sx }) {
    const classes = useStyles();
    const { staking } = useSelector(({ staking }) => ({ staking }));
    const _redeeming = useRef({});
    const dispatch = useDispatch();
    const [redeeming, setRedeeming] = useState({});

    async function handleWithdraw(div) {
        _redeeming.current[div.token] = true;
        setRedeeming(_redeeming.current);
        try {
            await dispatch(withdrawDivToken(div.token));
            dispatch(setSnackbar({ message: 'Redeemed!', severity: 'success' }));
        } catch (error) {
            console.log(error);
            dispatch(setSnackbar({ message: metamaskErrorWrap(error), severity: 'error' }));
        }
        delete _redeeming.current[div.token];
        setRedeeming(_redeeming.current);
    }

    return (
        <Card component={Paper} className={classes.root} sx={sx}>
            <Box className={classes.header}>
                <Typography variant="h6">Div Tokens</Typography>
            </Box>
            <Box className={classes.description}>
                <Typography>Some shpeel about how div tokens are awarded</Typography>
            </Box>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="tableHeader1"></Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1">Token</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1">Amount</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="tableHeader1"></Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {staking.divs?.map?.((div, i) => {
                            return (
                                <TableRow key={`stake-${i}`}>
                                    <TableCell>
                                        <img src={div.logo} style={{ height: 25, width: 25 }} />
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="font1" fontWeight="500">
                                            {div.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="font1" fontWeight="500">
                                            {div.amount.numberWithCommas(2)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip arrow title={`Withdraw your earned divs from your total shares!`}>
                                            <span>
                                                <LoadingButton
                                                    color="secondary"
                                                    loading={redeeming[div?.token]}
                                                    onClick={() => handleWithdraw(div)}
                                                    disabled={div.amount === 0}
                                                >
                                                    Withdraw
                                                </LoadingButton>
                                            </span>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
}
