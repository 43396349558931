import * as React from 'react';
import Card from 'components/Cards/Card';
import { Box, Typography, Divider } from '@mui/material';

import { BurnedIcon, CirculatingIcon, StakedIcon, SupplyIcon } from 'assets/images/icons';
import useStyles from './styles';
import { useSelector } from 'react-redux';

const overviewData = [
    {
        icon: SupplyIcon,
        title: 'Total Rewards',
        description: 'Total NXN rewarded to miners',
        key: 'rewards',
    },
    {
        icon: BurnedIcon,
        title: 'Total Mining Penalties',
        description: 'Penalties accrued from early withdraws',
        key: 'penalties',
    },
    {
        icon: CirculatingIcon,
        title: 'Total Withdrawn',
        description: 'Total NXN withdrawn from miners',
        key: 'withdrawn',
    },
    {
        icon: StakedIcon,
        title: 'AA Dai Deposited',
        description: 'Dai deposited in Adoption Amplifer',
        key: 'daiDeposited',
    },
    {
        icon: StakedIcon,
        title: 'AA Usdc Deposited',
        description: 'USDC deposited in Adoption Amplifer',
        key: 'usdcDeposited',
    },
];

export default function Overview() {
    const classes = useStyles();

    const { dashboard } = useSelector(({ dashboard }) => ({ dashboard }));
    const { staking = {}, bonding = {} } = dashboard?.overalls ?? {};
    // Maybe kinda silly

    let organizedData = {
        rewards: staking?.rewards !== null ? `${staking?.rewards?.numberWithCommas?.(2)} NXN` : 'N/A',
        penalties: staking?.penalty !== null ? `${staking?.penalty?.numberWithCommas?.(2)} NXN` : 'N/A',
        withdrawn: staking?.withdrawn !== null ? `${staking?.withdrawn?.numberWithCommas?.(2)} NXN` : 'N/A',
        daiDeposited: bonding?.daiBondDeposits !== null ? `${bonding?.daiBondDeposits?.numberWithCommas?.(2)} DAI` : 'N/A',
        usdcDeposited: bonding?.usdcBondDeposits !== null ? `${bonding?.usdcBondDeposits?.numberWithCommas?.(2)} USDC` : 'N/A',
    };

    return (
        <Card color="darkBlack" border="secondary" className={classes.root}>
            <Box className={classes.content}>
                {overviewData.map((data, index) => (
                    <React.Fragment key={`stat-${index}`}>
                        <Box className={classes.flexGapBox} sx={{ marginTop: index === 0 ? 0 : 1 }}>
                            <Box className={classes.sectionGapBox} sx={{ marginBottom: 1 }}>
                                {<data.icon />}
                                <Box>
                                    <Typography variant="font2" sx={{ fontWeight: '500' }}>
                                        {data.title}
                                    </Typography>
                                    <Typography variant="font3">{data.description}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.overviewBox}>
                                <Typography variant="font2" sx={{ fontWeight: '500' }}>
                                    {organizedData[data.key]}
                                </Typography>
                                {data.subValue && <Typography variant="font4">{data.subValue}</Typography>}
                            </Box>
                        </Box>
                        {index === 0 || index === 2 ? (
                            <Divider variant="middle" className={classes.dividerColor} sx={{ borderBottomWidth: 2, marginY: 1 }} />
                        ) : null}
                    </React.Fragment>
                ))}
            </Box>
        </Card>
    );
}
